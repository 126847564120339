
function calcPath(itms){

    const _getParentFullPath = (parent) => {
        
        if(!parent){ return '/' }
        if(parent.path==='/'){ return '/' + parent.name }

        return parent.path + '/' + parent.name
    }

    const _calcItmPath = (itmsById, itmId) => {

        if(!itmId){
            return
        }
        const _item = itmsById[itmId]
        if(!_item){
            return
        }
        if(_item.pathCalc){
            return
        }

        _calcItmPath(itmsById, _item.parent)

        _item.path = _getParentFullPath(itmsById[_item.parent])
        _item.pathCalc = true
    }

    const _itmsById = {}
    for (const _itm of itms) {
        _itmsById[_itm.id]={..._itm}
    }

    itms.forEach(itm => {
        return _calcItmPath(_itmsById, itm.id)
    })

    return itms.map(it => {
        return {
            ...it,
            path: _itmsById[it.id] && _itmsById[it.id].path
        }
    })
}

export default calcPath