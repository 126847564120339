import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import {parse} from 'query-string';

import {debug} from '../../../utils/logging'
import PrjDetailsCont from './PrjDetailsCont';
import { loadCustProjects } from '../../../store/actions/projectsActions';
import PrjLstCont from './PrjLstCont';
import PrjDashboard from './PrjDashboard';

class AdmPrjMain extends PureComponent {
    static propTypes = {
        xs: PropTypes.bool,
    }

    componentDidMount = () => {
        debug(`AdmPrjMain CDM: ${this.props.location.pathname}${this.props.location.search}`)
        const {qs} = this.props

        if(qs && qs['c']){
            this.props.loadCustProjects(qs['c'])
        }
    };

    render = () => {
        debug(`AdmPrjMain RENDER: ${this.props.location.pathname}${this.props.location.search}`)

        const _qs = parse(this.props.location.search) || {}
        //console.log(_qs)

        const {history, xs, auth} = this.props

        if(!auth || !auth.uid){
            return <Redirect to='/login' />
        }

        if(xs){
            return <div className='container-fluid fullHeight px-0'>
                {
                    <div style={{height: '100%', display: (!_qs['p'])?'block':'none'}}><PrjLstCont history={history} custId={_qs['c']} prjId={_qs['p']} /></div>
                }
                {
                    _qs['p'] && <PrjDetailsCont key={`prjDet_${_qs['p']}`} history={history} custId={_qs['c']} prjId={_qs['p']} tab={_qs['tab']} qs={_qs} />
                }
            </div>
        }

        return (
            <div className='container-fluid fullHeight'>
                <div className="row fullHeight">
                    <div className="col-sm-5 col-md-4 fullHeight px-0">
                        <PrjLstCont history={history} custId={_qs['c']} prjId={_qs['p']} />
                    </div>
                    <div className="col-sm-7 col-md-8 fullHeight px-0 rasterLeft">
                        {
                            !_qs['p'] && <PrjDashboard />
                        }
                        {
                            _qs['p'] && <PrjDetailsCont key={`prjDet_${_qs['p']}`} history={history} custId={_qs['c']} prjId={_qs['p']} tab={_qs['tab']} qs={_qs} />
                        }
                    </div>
                </div>
            </div>)
    }
}

const mapStateToProps = (state) => {  
    return{
      auth: state.firebase.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadCustProjects: (custId) => dispatch(loadCustProjects(custId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdmPrjMain)