import * as actTypes from '../actions/actionTypes'
import { BugCrash } from '../../services/BugCrash'

import Axios from '../../services/Axios'
import {lbl} from '../../lbls'
import { discardChgs } from './changesActions';
import { registerFeedback } from './feedbackActions';

export const loadCustTenants = (custId) => {
    return async (dispatch, getState) => {

        try{
            dispatch({type: actTypes.LOAD_CUST_TNTS_STARTED, custId})

            const resp = await Axios.get(`/customers/${custId}/tenants`)
            if(resp && (resp.status===200)){
                return dispatch({type: actTypes.LOAD_CUST_TNTS_READY_OK, custId, data: resp.data})
            }

            throw new Error(lbl.err_load_cust_tnts)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.LOAD_CUST_TNTS_READY_PB, custId, err})
        }
    }
}


export const loadTenant = (custId, tntId) => {
    return async (dispatch, getState) => {

        try{
            dispatch({type: actTypes.LOAD_TNT_STARTED, custId, tntId})

            const resp = await Axios.get(`/customers/${custId}/tenants/${tntId}`)
            if(resp && (resp.status===200)){
                return dispatch({type: actTypes.LOAD_TNT_READY_OK, custId, tntId, data: resp.data})
            }

            throw new Error(lbl.err_load_tnt)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.LOAD_TNT_READY_PB, custId, tntId, err})
        }
    }
}

export const saveTenant = (custId, tntId, changes) => {
    return async (dispatch, getState) => {

        try{
            dispatch({type: actTypes.SAVE_TNT_STARTED, custId, tntId})

            const _tenantsState = (await getState())['tenants']
            //const _origVersion = _tenantsState && _tenantsState.tntDet && _tenantsState.tntDet[tntId] && _tenantsState.tntDet[tntId].data
            const _origVersion = _tenantsState && _tenantsState[custId] && _tenantsState[custId].tnts && _tenantsState[custId].tnts[tntId] && _tenantsState[custId].tnts[tntId].data

            //console.log(_origVersion)
            
            const _full = Object.assign({}, (_origVersion && _origVersion.full) || {})
            const _compact = Object.assign(
                {}, 
                (_origVersion && _origVersion.compact) || {},
                (changes.firma)?{firma: changes.firma.val}:{},
                (changes.street)?{street: changes.street.val}:{},
                (changes.zip)?{zip: changes.zip.val}:{},
                (changes.city)?{city: changes.city.val}:{},
            )

            const _tntData = {
                timestampBefore: (_origVersion && _origVersion.timestamp) || 0,
                parent: (_origVersion && _origVersion.parent) || null,
                name: (changes.name)?changes.name.val:(_origVersion.name || ''),
                container: (changes.container)?(changes.container.val==='TRUE'):_origVersion.container,
                compact: _compact,
                full: _full,
            }

            const resp = await Axios.post(`/customers/${custId}/tenants/${tntId}`, _tntData)
            if(resp && (resp.status===200)){
                /*dispatch({
                    type: actTypes.UPD_TNT_FROM_CHANGES,
                    custId,
                    tntId,
                    changes,
                    lastSaveTimestamp: resp.data.timestamp,
                })*/

                dispatch(registerFeedback(lbl.feedback_save_tnt_ok.replace('%tnt', _tntData.name), false))
                dispatch(loadTenant(custId, tntId))
                dispatch(discardChgs(`tnt-${tntId}`))

                return dispatch({type: actTypes.SAVE_TNT_READY_OK, custId, tntId})
            }
            else{
                //TODO: Save-Tenant Fehler Behandlung
            }

            //throw new Error(lbl.err_load_my_cust)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.SAVE_TNT_READY_PB, custId, tntId, err})
        }
    }
}
