export default {
    dash: 'Dashboard',
    menu: 'Menü',
    back: 'Zurück',
    tnt: 'Tenant',
    prj: 'Projekt',
    prj_data: 'Projekt-Daten',
    prj_mnt_create: 'Mount erstellen',
    prj_mnts_lst: 'Mounts',
    info: 'Info',
    details: 'Details',
    edit: 'ändern',
    save: 'Speichern',
    del: 'Löschen',
    cancel: 'Abbrechen',
    email: 'E-Mail',
    name: 'Name',
    city: 'Stadt',
    street: 'Straße',
    company: 'Firma',
    zip: 'Postleitzahl',
    entries: 'Einträge',
    pls_wait: 'Bitte warten...',
    load_pls_wait: 'Die Daten werden geladen. Bitte warten...',
    save_pls_wait: 'Die Daten werden gespeichert. Bitte warten...',
    del_pls_wait: 'Die Daten werden gelöscht. Bitte warten...',
    tnt_container: 'Enthält nur...',
    tnt_container_subtnt: 'andere Tenants, aber keine Daten.',
    tnt_container_daten: 'Daten aber keine andere Tenants.',
    bitte_tnt: 'Bitte wählen Sie ein Tenant!',
    bitte_prj: 'Bitte wählen Sie ein Projekt!',
    feedback_save_tnt_ok: 'Tenant %tnt erfolgreich gespeichert.',
    feedback_save_prj_ok: 'Projekt %prj erfolgreich gespeichert.',
    feedback_del_prj_ok: 'Projekt %prj erfolgreich gelöscht.',
    feedback_tnt_mount_ok: 'Tenant %tnt erfolgreich gemounted.',
    feedback_del_tnt_mount_ok: 'Mount %tnt erfolgreich gelöscht.',
    feedback_save_usr_roles_ok: 'Rollen vom Benutzer %usr erfolgreich gespeichert.',
    feedback_del_usr_roles_ok: 'Rollen vom Benutzer %usr erfolgreich gelöscht.',
    new_prj: 'Neues Projekt',
    del_prj: 'Projekt löschen?',
    del_conf: 'Wirklich löschen?',
    del_prj_mnt: 'Projekt-Mount löschen?',
    del_prj_usr_roles: 'Benutzer-Rollen löschen?',
    mnt_tnt: 'Mount Tenant...',
    new_usr_role: 'Rollen einem anderen Benutzer hinzufügen...',
    usr_roles_lst: 'Benutzer-Rollen',
    usr_roles_choose_usr: 'Benutzer auswählen...',

    title_prj_lst: 'Projekte',
    no_data: 'Keine Daten vorhanden.',

    err_load_tnt: 'Fehler beim Laden der Tenant-Daten!',
    err_load_prj: 'Fehler beim Laden der Projekt-Daten!',
    err_load_cust_tnts: 'Fehler beim Laden der Tenants-Daten!',
    err_load_cust_shares_to_us: 'Fehler beim Laden der Freigaben!',
    err_load_cust_prjs: 'Fehler beim Laden der Projekte-Daten!',
    err_load_prj_mounts: 'Fehler beim Laden der Projekt-Mounts Daten!',
    err_save_prj_mount: 'Fehler beim Speichern der Projekt-Mount Daten!',
    err_del_prj_mount: 'Fehler beim Löschen der Projekt-Mount Daten!',
    err_load_cust_users: 'Fehler beim Laden der Benutzer-Daten!',
    err_load_prj_roles: 'Fehler beim Laden der Projekt-Rollen Daten!',
    err_load_roles: 'Fehler beim Laden der Rollen Daten!',
    err_del_usr_roles_mount: 'Fehler beim Löschen der Benutzer-Rollen!',

    search_usr_role: 'Benutzer-Rolle suchen...',
    search_usr: 'Benutzer suchen...',
    search_prj: 'Projekt suchen...',
    search_tnt: 'Tenant suchen...',

    usr: 'Benutzer',

    tab_roles: 'Rollen',
    tab_data: 'Daten',
    tab_mounts: 'Mounts',
}