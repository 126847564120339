import {generatePath} from 'react-router-dom';
import {stringify} from 'query-string';

function genPath(urlPattern, urlParams, queryStringParams){

    const _queryString = stringify(queryStringParams)

    if(!_queryString){
        return generatePath(urlPattern, urlParams)
    }

    return `${generatePath(urlPattern, urlParams)}?${_queryString}`
}

export default genPath