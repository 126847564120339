import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';

import { connect } from 'react-redux'
import TntDetails from './TntDetails';

import {debug} from '../../../utils/logging'
import genPath from '../../../routing/genPath';

import { loadTenant, saveTenant} from '../../../store/actions/tenantsActions';
import { registerChg } from '../../../store/actions/changesActions';

class TntDetailsCont extends PureComponent {

    static propTypes = {
        history: PropTypes.object,
        custId: PropTypes.string,
        tntId: PropTypes.string,
        tab: PropTypes.string,
    }

    _onChgTab = (selTab) => {
        const {history, custId, tntId} = this.props
        history.push(genPath('/admin/tenants', null, {c: custId, t: tntId, act: 'det', tab: selTab}))
    }

    _onChgTnt = (tnt) => {
        const {history, custId} = this.props
        const selTab = (tnt.data.container)?'tenants':'data'

        history.push(genPath('/admin/tenants', null, {c: custId, t: tnt.data.id, act: 'det', tab: selTab}))
    }

    _onSaveData = (changes) => {
        const {custId, tntId} = this.props
        this.props.saveTenant(custId, tntId, changes)
    }

    _registerChg = (fieldName, value) => {
        const {tntId} = this.props
        this.props.registerChg(`tnt-${tntId}`, fieldName, value)
    }

    componentDidMount = () => {

        const {custId, tntId} = this.props

        debug(`TntDetailsCont CDM: TenantID=${tntId}`)

        this.props.loadTenant(custId, tntId)
    };

    render = () => {

        const {tntId, tab, tntDet, tenants} = this.props
        debug(`TntDetailsCont RENDER: TenantID=${tntId}`)

        return (<React.Fragment>
            <TntDetails 
                tntId={tntId} 
                tntDet={tntDet}
                onChgTab={this._onChgTab} 
                tab={tab} 
                onSaveData={this._onSaveData} 
                changes={this.props.changes || {}}
                registerChg={this._registerChg}
                tenants={tenants || {}}
                onChgTnt={this._onChgTnt}
            />
        </React.Fragment>)
    }
}

const mapStateToProps = (state, ownProps) => {

    const {custId, tntId} = ownProps

    return{
        tntDet: state.tenants && state.tenants[custId] && state.tenants[custId].tnts && state.tenants[custId].tnts[tntId],
        changes: ownProps.tntId && state.changes[`tnt-${tntId}`],
        tenants: state.tenants && state.tenants[custId]
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadTenant: (custId, tntId) => dispatch(loadTenant(custId, tntId)),
        saveTenant: (custId, tntId, changes) => dispatch(saveTenant(custId, tntId, changes)),
        registerChg: (objKennung, fieldName, value) => dispatch(registerChg(objKennung, fieldName, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TntDetailsCont)