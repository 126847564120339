import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import GrpHead from '../../ui/GrpHead';
import PlsWait from '../../ui/PlsWait';
import { lbl } from '../../../lbls';

import Modal from 'react-bootstrap/Modal'

// TODO: loadPb, savePb
// TODO: tnt @ createNew
// TODO: Validierung
// TODO: xs -> sm Design
// TODO: Delete action
// TODO: Save action

function PrjDetailsData({prj, loading, saving, deleting, onSave, registerChg, changes, delPrj}) {
    const [validated, setValidated] = useState(false);
    const [del, setDel] = useState(false);

    const handleSubmit = event => {

      const form = event.currentTarget;

      event.preventDefault();
      event.stopPropagation();

      setValidated(true);

      if (form.checkValidity() === false) {
        return false
      }

      onSave(changes)
    };

    const _handleChange = (evnt) => {
        const {name, value} = evnt.target
        registerChg(name, value)
    }

    const _handleCloseDel = (evnt) => {
        setDel(false)
    }

    const _handleDel = (evnt) => {
        delPrj(prj.id, prj.name)
        setDel(false)
    }

    return (
        <React.Fragment>
            { saving && <PlsWait msg={lbl.save_pls_wait} /> }
            { loading && !saving && <PlsWait msg={lbl.load_pls_wait} /> }
            { deleting && <PlsWait msg={lbl.del_pls_wait} /> }

            <Form className="p-3" noValidate validated={validated} onSubmit={handleSubmit}>

                <GrpHead title={lbl.info} />

                <Form.Group as={Row} controlId="inpName">
                    <Form.Label column xs="12" sm="3">{lbl.name}:</Form.Label>
                    <Col xs="12" sm="9">
                        <Form.Control name="name" type="text" placeholder={lbl.name} 
                            value={changes.name?changes.name.val:(prj.name || '')} 
                            onChange={_handleChange} required />
                    </Col>
                </Form.Group>

                <div className="d-flex justify-content-between dividerTop mt-3 pt-2">
                    <Button variant="outline-primary" size="sm" type="submit">{lbl.save}</Button>
                    <Button variant="outline-danger" size="sm" type="button" onClick={(evnt) => {
                        setDel(true)
                    }}>{lbl.del}</Button>
                </div>
            </Form>

            <Modal show={del} onHide={_handleCloseDel}>
                <Modal.Header closeButton>
                    <Modal.Title>{lbl.del_prj}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{lbl.del_conf}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={_handleCloseDel}>{lbl.cancel}</Button>
                    <Button variant="danger" onClick={_handleDel}>{lbl.del}</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
  )
}

PrjDetailsData.propTypes = {
    loading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    prj: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    registerChg: PropTypes.func.isRequired,
    changes: PropTypes.object.isRequired, 
}

export default PrjDetailsData
