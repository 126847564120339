import React from 'react'
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { lbl } from '../../lbls';

PlsWait.propTypes={
    msg: PropTypes.string,
}

export default function PlsWait({msg}) {
    return (
        <Alert variant="secondary" className="d-flex justify-content-center align-items-center ml-3 mr-3">
            <Spinner
                as="span"
                size="sm"
                animation="border"
                role="status"
                aria-hidden="true"
                className="plsWait"
            />&nbsp;&nbsp;<span className="plsWait">{msg || lbl.pls_wait}</span>
        </Alert>
    )
}