import React from 'react'
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { lbl } from '../../lbls';
import Button from 'react-bootstrap/Button';

function ConfDel({id, doDel, doClose, title, question}){

    const _handleCloseDel = (evnt) => {
        doClose()
    }

    const _handleDel = (evnt) => {
        doDel(id)
        doClose()
    }

    return (
        <Modal show={id!==''} onHide={_handleCloseDel}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{question}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={_handleCloseDel}>{lbl.cancel}</Button>
                <Button variant="danger" onClick={_handleDel}>{lbl.del}</Button>
            </Modal.Footer>
        </Modal>
    )
}

ConfDel.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    doDel: PropTypes.func.isRequired,
    doClose: PropTypes.func.isRequired,
}

export default ConfDel