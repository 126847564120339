import * as actTypes from '../actions/actionTypes'
import { BugCrash } from '../../services/BugCrash'

import Axios from '../../services/Axios'
import {lbl} from '../../lbls'

import { discardChgs } from './changesActions';
import { registerFeedback } from './feedbackActions';
import { debug } from '../../utils/logging';

export const loadCustProjects = (custId) => {
    return async (dispatch, getState) => {

        try{
            dispatch({type: actTypes.LOAD_CUST_PRJS_STARTED, custId})

            const resp = await Axios.get(`/customers/${custId}/projects`)
            if(resp && (resp.status===200)){
                return dispatch({type: actTypes.LOAD_CUST_PRJS_READY_OK, custId, data: resp.data})
            }

            throw new Error(lbl.err_load_cust_prjs)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.LOAD_CUST_PRJS_READY_PB, custId, err})
        }
    }
}

export const addProject = (custId, prjId, name, afterCreate) => {
    return async (dispatch, getState) => {
        dispatch({type: actTypes.ADD_PRJ, custId, prjId, name})

        if(afterCreate){
            setTimeout(()=>{afterCreate()}, 100)
        }
    }
}

export const loadProject = (custId, prjId) => {
    return async (dispatch, getState) => {

        try{
            const _state = (await getState())['projects']
            if(_state && _state[custId] && _state[custId].prjs && _state[custId].prjs[prjId] && _state[custId].prjs[prjId].new){
                debug(`Das Projekt ${prjId} ist neu -> laden vom Server kann nichts bringen!`)
                return
            }

            dispatch({type: actTypes.LOAD_PRJ_STARTED, custId, prjId})

            const resp = await Axios.get(`/customers/${custId}/projects/${prjId}`)
            if(resp && (resp.status===200)){
                return dispatch({type: actTypes.LOAD_PRJ_READY_OK, custId, prjId, data: resp.data})
            }

            throw new Error(lbl.err_load_prj)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.LOAD_PRJ_READY_PB, custId, prjId, err})
        }
    }
}

const _finalizeDelProjectOk = (dispatch, custId, prjId, prjName, afterDelOk) => {

    dispatch({type: actTypes.DEL_PRJ_READY_OK, custId, prjId})
    dispatch(registerFeedback(lbl.feedback_del_prj_ok.replace('%prj', prjName), false))

    setTimeout(
        () => {afterDelOk()},
        50
    )
}

export const delProject = (custId, prjId, prjName, afterDelOk) => {
    return async (dispatch, getState) => {

        try{
            dispatch({type: actTypes.DEL_PRJ_STARTED, custId, prjId})

            const _state = (await getState())['projects']
            if(_state && _state[custId] && _state[custId].prjs && _state[custId].prjs[prjId] && _state[custId].prjs[prjId].new){
                //new project -> there is nothing on the server to be deleted!
                _finalizeDelProjectOk(dispatch, custId, prjId, prjName, afterDelOk)
                return
            }

            const resp = await Axios.delete(`/customers/${custId}/projects/${prjId}`)
            if(resp && (resp.status===200)){
                _finalizeDelProjectOk(dispatch, custId, prjId, prjName, afterDelOk)
            }
            else{
                //TODO: Delete-Project Fehler Behandlung
            }
        }
        catch(err){
            console.log(err)

            BugCrash.notify(err)
            dispatch({type: actTypes.DEL_PRJ_READY_PB, custId, prjId, err})
        }
    }
}

export const saveProject = (custId, prjId, changes) => {
    return async (dispatch, getState) => {

        try{
            dispatch({type: actTypes.SAVE_PRJ_STARTED, custId, prjId})

            const _projectsState = (await getState())['projects']
            const _origVersion = _projectsState && _projectsState[custId] && _projectsState[custId].prjs && _projectsState[custId].prjs[prjId] && _projectsState[custId].prjs[prjId].data

            //console.log(_origVersion)

            const _prjData = {
                name: (changes.name)?changes.name.val:(_origVersion.name || ''),
            }

            const resp = await Axios.post(`/customers/${custId}/projects/${prjId}`, _prjData)
            if(resp && (resp.status===200)){

                dispatch({type: actTypes.SAVE_PRJ_READY_OK, custId, prjId})
                dispatch(loadProject(custId, prjId))
                dispatch(discardChgs(`prj-${prjId}`))
                dispatch(registerFeedback(lbl.feedback_save_prj_ok.replace('%prj', _prjData.name), false))
                return 
            }
            else{
                //TODO: Save-Project Fehler Behandlung
            }

            //throw new Error(lbl.err_load_my_cust)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.SAVE_PRJ_READY_PB, custId, prjId, err})
        }
    }
}
