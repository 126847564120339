import * as actTypes from '../actions/actionTypes'

const initState = {}

const changesReducer = (state = initState, action) => {

    switch(action.type){

        case actTypes.CHG_REGISTER:
            return {
                ...state,
                [action.objKennung]: {
                    ...state[action.objKennung],
                    [action.fieldName]: {
                        val: action.newValue,
                    },
                },
            }

        case actTypes.CHG_DISCARD:
            const { [action.objKennung]: value, ...allWithoutDiscarded } = state;

            return {
                ...allWithoutDiscarded,
            }

        default:
            return state
    }
}

export default changesReducer;