import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Media from 'react-media';

import CustDynTheme from './components/themes/CustDynTheme';
import RouteDispacher from './components/RouteDispacher';
import ActionFeedback from './components/ui/ActionFeedback';

function App() {

  return (
    <BrowserRouter>
      <CustDynTheme custId='7771' />
      <Media query="(max-width: 599px)">
        { matches => <RouteDispacher xs={matches} /> }
      </Media>
      <ActionFeedback />
    </BrowserRouter>)
}

export default App;