import React from 'react'
import { lbl } from '../../../lbls';

export default function TntDashboard() {
    return (
        <React.Fragment>
            <header className="NRHead shadow"></header>
            <div className="NRBody p-3">
                {lbl.bitte_tnt}
            </div>
            <footer className="NRFoot d-flex justify-content-around"></footer>
        </React.Fragment>
    )
}
