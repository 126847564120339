import * as actTypes from '../actions/actionTypes'

const initState = {
  tntDet: {},
}

const tenantsReducer = (state = initState, action) => {

  switch(action.type){

    case actTypes.LOAD_CUST_TNTS_STARTED:

        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                loading: true,
                err: null,
                tnts: {},
            }
        }

    case actTypes.LOAD_CUST_TNTS_READY_OK:

        const tnts={}
        action.data.own.forEach(tnt => {
            tnts[tnt.id] = Object.assign({}, state[action.custId][tnt.id], {data: tnt})
        })
        action.data.shares.forEach(shr => {
            shr.tenants.forEach(tnt => {
                tnts[tnt.id] = Object.assign({}, state[action.custId][tnt.id], {data: tnt})
            })
        })

        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                loading: false,
                err: null,
                tnts,
            }
        }

    case actTypes.LOAD_CUST_TNTS_READY_PB:
        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                loading: false,
                err: action.err,
                tnts: {},
            }
        }

    case actTypes.SAVE_TNT_STARTED:
        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                tnts: {
                    ...(state && state[action.custId] && state[action.custId].tnts),
                    [action.tntId]: {
                        ...(state && state[action.custId] && state[action.custId].tnts && state[action.custId].tnts[action.tntId]),
                        saving: true,
                        errSave: null,    
                    }
                }
            }
        }

    case actTypes.SAVE_TNT_READY_PB:
        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                tnts: {
                    ...(state && state[action.custId] && state[action.custId].tnts),
                    [action.tntId]: {
                        ...(state && state[action.custId] && state[action.custId].tnts && state[action.custId].tnts[action.tntId]),
                        saving: false,
                        errSave: action.err,    
                    }
                }
            }
        }

    case actTypes.SAVE_TNT_READY_OK:

        //TODO: Inform user that everything is all right with the save
        //TODO: Merge the saved changes into the list of tenants. 


        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                tnts: {
                    ...(state && state[action.custId] && state[action.custId].tnts),
                    [action.tntId]: {
                        ...(state && state[action.custId] && state[action.custId].tnts && state[action.custId].tnts[action.tntId]),
                        saving: false,
                        errSave: null,    
                    }
                }
            }
        }

    case actTypes.LOAD_TNT_STARTED:
        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                tnts: {
                    ...(state && state[action.custId] && state[action.custId].tnts),
                    [action.tntId]: {
                        ...(state && state[action.custId] && state[action.custId].tnts && state[action.custId].tnts[action.tntId]),
                        loading: true,
                        err: null,    
                    }
                }
            }
        }

    case actTypes.LOAD_TNT_READY_PB:
        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                tnts: {
                    ...(state && state[action.custId] && state[action.custId].tnts),
                    [action.tntId]: {
                        ...(state && state[action.custId] && state[action.custId].tnts && state[action.custId].tnts[action.tntId]),
                        loading: false,
                        err: action.err,
                    }
                }
            }
        }

    case actTypes.LOAD_TNT_READY_OK:
        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                tnts: {
                    ...(state && state[action.custId] && state[action.custId].tnts),
                    [action.tntId]: {
                        ...(state && state[action.custId] && state[action.custId].tnts && state[action.custId].tnts[action.tntId]),
                        loading: false,
                        err: null,
                        data: {
                            ...(state && state[action.custId] && state[action.custId].tnts && state[action.custId].tnts[action.tntId] && state[action.custId].tnts[action.tntId].data),
                            ...action.data,
                        },
                    }
                }
            }
        }

    default:
        return state
    }
}

export default tenantsReducer;