import * as actTypes from '../actions/actionTypes'

const initState = {
}

const rolesReducer = (state = initState, action) => {

    switch(action.type){

        case actTypes.LOAD_ROLES_STARTED:

            return {
                ...state,
                loading: true,
                err: null,
                roles: {},
            }

        case actTypes.LOAD_ROLES_READY_PB:

            return {
                ...state,
                loading: false,
                err: action.err,
            }

        case actTypes.LOAD_ROLES_READY_OK:

            return {
                ...state,
                loading: false,
                err: null,
                roles: (action.data.list && action.data.list.reduce((prv, cur) => {
                    return {
                        ...prv,
                        [cur.id]: cur,
                    }
                }, {})) || {},
            }

        default:
            return state
    }
}

export default rolesReducer;