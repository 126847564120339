import React, {useState} from 'react'
import PlsWait from '../../ui/PlsWait';
import { lbl } from '../../../lbls';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function TntRoots({tenants, onChgTnt, goBack}) {
    const [search, setSearch] = useState('')

    const _selTnts = {}
    const _handleChange = (evnt) => {
        const {value} = evnt.target
        setSearch(value)
    }

    //console.log(tenants)

    if(!tenants.loading && tenants.tnts){
        const _lowerCaseSearch = search.toLocaleLowerCase()
        const _filter = (search)?(t => t.data.name.toLocaleLowerCase().includes(_lowerCaseSearch)):(t => !t.data.parent)

        Object.values(tenants.tnts).filter(_filter).forEach(t => {
            _selTnts[t.data.id]=t
        })
    }

    const _prepTnts = Object.values(_selTnts).sort((t1, t2) => t1.data.name>t2.data.name)

    return (
        <React.Fragment>
            <header className="NRHead shadow d-flex align-items-center">
                <Button variant="link" className="txt-act-color" onClick={evnt => {
                    goBack()
                }}>{lbl.menu}</Button>
            </header>
            <div className="NRBody">
                <div className="p-3">
                    {
                        tenants.loading && <PlsWait msg={lbl.load_pls_wait} />
                    }
                    {
                        <React.Fragment>
                            <Form.Control className="mb-3 text-center" size="sm" name="search" type="text" placeholder={lbl.tnt} 
                            value={search || ''} 
                            onChange={_handleChange} />

                            <Table striped hover bordered>
                                <tbody>
                                    {
                                        _prepTnts.map(tnt => {
                                            return <tr key={tnt.data.id}>
                                                <td><Button variant="link" className="txt-title-color" block onClick={evnt => onChgTnt(tnt)} style={{textAlign: 'left'}}>{tnt.data.name}</Button></td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                        </React.Fragment>
                    }
                </div>
            </div>
            <footer className="NRFoot d-flex justify-content-around align-items-center">
                {_prepTnts.length} {lbl.entries}
            </footer>
        </React.Fragment>
    )
}

export default TntRoots