import React from 'react'
import PropTypes from 'prop-types';

import Table from 'react-bootstrap/Table'
import GrpHead from '../../ui/GrpHead';
import PlsWait from '../../ui/PlsWait';
import { lbl } from '../../../lbls';
import Button from 'react-bootstrap/Button';

function TntDetailsSubtenants({loading, tenants, parentTntId, onClickTnt}) {

    if(loading){
        return <PlsWait msg={lbl.load_pls_wait} />
    }

    const _preparedTnts = Object.values(tenants).filter(t => t.data.parent === parentTntId).sort((t1, t2) => t1.data.name>=t2.data.name)

    return (
        <div className="p-3">
            <GrpHead title={'Subtenants'} />

            <Table striped hover bordered>
                <tbody>
                    {
                        _preparedTnts.map(tnt => {
                            return <tr key={tnt.data.id}>
                                <td><Button variant="link" className="txt-title-color" block onClick={evnt => onClickTnt(tnt)} style={{textAlign: 'left'}}>{tnt.data.name}</Button></td>
                            </tr>
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}

TntDetailsSubtenants.propTypes = {
    loading: PropTypes.bool.isRequired,
    onClickTnt: PropTypes.func.isRequired, 
    tenants: PropTypes.object.isRequired, 
    parentTntId: PropTypes.string.isRequired, 
}

export default TntDetailsSubtenants