import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import {parse} from 'query-string';

import {debug} from '../../../utils/logging'
//import genPath from '../../../routing/genPath';
import TntDetailsCont from './TntDetailsCont';
import { loadCustTenants } from '../../../store/actions/tenantsActions';
import { loadCustShares2Us } from '../../../store/actions/sharesActions';
import TntRootsCont from './TntRootsCont';
import TntDashboard from './TntDashboard';

class AdmTntMain extends PureComponent {
    static propTypes = {
        xs: PropTypes.bool,
    }

    componentDidMount = () => {
        debug(`AdmTntMain CDM: ${this.props.location.pathname}${this.props.location.search}`)
        const {qs} = this.props

        if(qs && qs['c']){
            this.props.loadCustShares2Us(qs['c'])
            this.props.loadCustTenants(qs['c'])
        }
    };

    render = () => {
        debug(`AdmTntMain RENDER: ${this.props.location.pathname}${this.props.location.search}`)

        const _qs = parse(this.props.location.search) || {}
        //console.log(_qs)

        const {history, xs, auth} = this.props

        if(!auth || !auth.uid){
            return <Redirect to='/login' />
        }

        if(xs){
            return <div className='container-fluid fullHeight px-0'>
                {
                    !_qs['t'] && <TntRootsCont history={history} custId={_qs['c']} tntId={_qs['t']} />
                }
                {
                    _qs['t'] && <TntDetailsCont key={`tntDet_${_qs['t']}`} history={history} custId={_qs['c']} tntId={_qs['t']} tab={_qs['tab']} />
                }
            </div>
        }

        return (
            <div className='container-fluid fullHeight'>
                <div className="row fullHeight">
                    <div className="col-sm-5 col-md-4 fullHeight px-0">
                        <TntRootsCont history={history} custId={_qs['c']} tntId={_qs['t']} />
                    </div>
                    <div className="col-sm-7 col-md-8 fullHeight px-0 rasterLeft">
                        {
                            !_qs['t'] && <TntDashboard />
                        }
                        {
                            _qs['t'] && <TntDetailsCont key={`tntDet_${_qs['t']}`} history={history} custId={_qs['c']} tntId={_qs['t']} tab={_qs['tab']} />
                        }
                    </div>
                </div>
            </div>)
    }
}

const mapStateToProps = (state) => {  
    return{
      auth: state.firebase.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadCustTenants: (custId) => dispatch(loadCustTenants(custId)),
        loadCustShares2Us: (custId) => dispatch(loadCustShares2Us(custId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdmTntMain)