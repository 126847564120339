import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

class SignIn extends Component {

  state = {
    email: '',
    password: ''
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signIn(this.state)
  }

  render() {
    if(this.props.auth.uid){
        return <Redirect to='/' />
    }

    const { authError } = this.props;

    return (
      <form className="white" onSubmit={this.handleSubmit}>
      <div className="container-fluid" style={{marginTop: '4rem'}}>

        <div className="row mt-3 mb-3">
          <div className="d-flex w-100 justify-content-center text-center">
            <h5 className="txt-act-color mb-3"><u>iNoRisk.DESK Authentifizierung</u></h5>
          </div>
        </div>

        {
          authError && (<div className="row mt-3">
          <div className="col-xs-12 d-flex w-100 justify-content-center text-center">
            <Alert variant="danger">{authError.message}</Alert>
          </div>
        </div>)
        }

        <div className="row mb-2">
          <div className="col-sm-4 text-right"><label htmlFor="email">Email</label></div>
          <div className="col-sm-8"><input type="email" id='email' onChange={this.handleChange} /></div>
        </div>
        <div className="row mb-2">
          <div className="col-sm-4 text-right"><label htmlFor="password">Password</label></div>
          <div className="col-sm-8"><input type="password" id='password' onChange={this.handleChange} /></div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-4"></div>
          <div className="col-sm-8"><Button type="submit" variant="outline-primary" className="txt-act-color">Login</Button></div>
        </div>
          
      </div>
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return{
    auth: state.firebase.auth,
    authError: state.auth.loginErr,
    waiting: state.auth.loginWaiting,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)