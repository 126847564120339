import * as actTypes from '../actions/actionTypes'
import { BugCrash } from '../../services/BugCrash'

import Axios from '../../services/Axios'
import {lbl} from '../../lbls'

import { registerFeedback } from './feedbackActions';

export const loadProjectMounts = (projId) => {

    return async (dispatch, getState) => {

        try{
            dispatch({type: actTypes.LOAD_PRJ_MOUNTS_STARTED, projId})

            const resp = await Axios.get(`/projects/${projId}/mounts`)
            if(resp && (resp.status===200)){
                return dispatch({type: actTypes.LOAD_PRJ_MOUNTS_READY_OK, projId, data: resp.data})
            }

            throw new Error(lbl.err_load_prj_mounts)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.LOAD_PRJ_MOUNTS_READY_PB, projId, err})
        }
    }
}

export const saveProjectMount = (custId, projId, tenantId) => {

    return async (dispatch, getState) => {

        try{
            dispatch({type: actTypes.SAVE_PRJ_MOUNT_STARTED, projId, tenantId})

            const resp = await Axios.post(`/projects/${projId}/mounts/${tenantId}`)
            if(resp && (resp.status===200)){

                const _state = (await getState())['tenants']
                //console.log(_state)
                const _tenant = _state && _state[custId] && _state[custId].tnts && _state[custId].tnts[tenantId]
                //console.log(_tenant)

                dispatch({type: actTypes.SAVE_PRJ_MOUNT_READY_OK, projId, tenantId, data: resp.data})
                dispatch(registerFeedback(lbl.feedback_tnt_mount_ok.replace('%tnt', ((_tenant && _tenant.data && _tenant.data.name) || tenantId)), false))
                dispatch(loadProjectMounts(projId))

                return
            }

            throw new Error(lbl.err_save_prj_mount)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.SAVE_PRJ_MOUNT_READY_PB, projId, tenantId, err})
        }
    }
}

export const delProjectMount = (custId, projId, tenantId) => {

    return async (dispatch, getState) => {

        try{
            dispatch({type: actTypes.DEL_PRJ_MOUNT_STARTED, projId, tenantId})

            const resp = await Axios.delete(`/projects/${projId}/mounts/${tenantId}`)
            if(resp && (resp.status===200)){

                const _state = (await getState())['tenants']
                //console.log(_state)
                const _tenant = _state && _state[custId] && _state[custId].tnts && _state[custId].tnts[tenantId]
                //console.log(_tenant)

                dispatch({type: actTypes.DEL_PRJ_MOUNT_READY_OK, projId, tenantId, data: resp.data})
                dispatch(registerFeedback(lbl.feedback_del_tnt_mount_ok.replace('%tnt', ((_tenant && _tenant.data && _tenant.data.name) || tenantId)), false))
                dispatch(loadProjectMounts(projId))

                return
            }

            throw new Error(lbl.err_del_prj_mount)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.DEL_PRJ_MOUNT_READY_PB, projId, tenantId, err})
        }
    }
}