import * as actTypes from '../actions/actionTypes'

const initState = {
  loginErr: null,
  loginWaiting: false,
  logoutErr: null,
  logoutWaiting: false,
}

const authReducer = (state = initState, action) => {

  switch(action.type){

    case actTypes.AUTH_LOGOUT_STARTED:
      return {
        ...state,
        logoutErr: null,
        logoutWaiting: true,
      }

    case actTypes.AUTH_LOGOUT_READY_OK:
      return {
        ...state,
        logoutErr: null,
        logoutWaiting: false,
      }
    
    case actTypes.AUTH_LOGOUT_READY_PB:
      return {
        ...state,
        logoutErr: action.err,
        logoutWaiting: false,
      }

    case actTypes.AUTH_LOGIN_STARTED:
      return {
        ...state,
        loginErr: null,
        loginWaiting: true,
      }

    case actTypes.AUTH_LOGIN_READY_OK:
      return {
        ...state,
        loginErr: null,
        loginWaiting: false,
      }

    case actTypes.AUTH_LOGIN_READY_PB:
      return {
        ...state,
        loginErr: action.err,
        loginWaiting: false,
      }

    default:
      return state
  }
};

export default authReducer;