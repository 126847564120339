import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Style } from "react-style-tag";

Style.setGlobalOptions({
  isCompressed: true,
  hasSourceMap: false,
  isMinified: true,
  isPrefixed: false
});

export default class CustDynTheme extends PureComponent {

  render() {

    const {custId} = this.props
    const bodyBgColor = (custId==='777')?'#EFEFEF':'#FFFFFF'
    const actColor = (custId==='777')?'#DE00DE':'#FCC42D'
    const barBgColor = (custId==='777')?'red':'#8E8E93'
    const mainRasterColor = '#AFAFAF';
    const dividerColor = '#f0f0f0';
    const titleColor = '#33332E'

    return (
      <Style>{`
      .txt-title-color{
        color: ${titleColor};
      }
      .txt-act-color {
        color: ${actColor};
      }
      .bg-act-color {
        background-color: ${actColor};
      }
      body{
        background-color: ${bodyBgColor};
      }
      .NRHead {
        background-color: ${barBgColor};
        border-bottom-color: ${mainRasterColor};
      }
      .NRFoot {
        background-color: ${barBgColor};
        border-top-color: ${mainRasterColor};
      }
      .rasterRight{
        border-right-color: ${mainRasterColor};
      }
      .rasterLeft{
        border-left-color: ${mainRasterColor};
      }
      .dividerTop{
        border-top-color: ${dividerColor};
      }
      .dividerBottom{
        border-bottom-color: ${dividerColor};
      }
      .btn-outline-primary{
        color: ${actColor};
        border-color: ${actColor};
      }
      .sel-row{
        color: ${actColor} !important;
      }
      .page-item.active .page-link{
        background-color: ${actColor} !important;  
      }
    `}</Style>
    );
  }
}

CustDynTheme.propTypes = {
  custId: PropTypes.string,
}
