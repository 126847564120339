import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';

import { connect } from 'react-redux'

import {debug} from '../../../utils/logging'
import genPath from '../../../routing/genPath';
import TntRoots from './TntRoots';


class TntRootsCont extends PureComponent {

    static propTypes = {
        history: PropTypes.object,
        custId: PropTypes.string,
        tntId: PropTypes.string,
    }

    _onChgTnt = (tnt) => {
        const {history, custId} = this.props
        const selTab = (tnt.data.container)?'tenants':'data'

        history.push(genPath('/admin/tenants', null, {c: custId, t: tnt.data.id, act: 'det', tab: selTab}))
    }

    _goBack = () => {
        const {history} = this.props
        //history.goBack()
        history.push('/')
    }

    componentDidMount = () => {

        const {custId, tntId} = this.props

        debug(`TntRootsCont CDM: CustID=${custId}, TenantID=${tntId}`)
    }

    render = () => {

        const {custId, tntId, tenants} = this.props
        debug(`TntRootsCont RENDER: CustID=${custId}, TenantID=${tntId}`)

        return (<React.Fragment>
            <TntRoots 
                tntId={tntId} 
                tenants={tenants || {}}
                onChgTnt={this._onChgTnt}
                goBack={this._goBack}
            />
        </React.Fragment>)
    }

}

const mapStateToProps = (state, ownProps) => {

    const {custId} = ownProps

    return{
        tenants: state.tenants && state.tenants[custId]
    }
}

export default connect(mapStateToProps)(TntRootsCont)
