import * as actTypes from '../actions/actionTypes'

const initState = {}

const sharesReducer = (state = initState, action) => {

  switch(action.type){

    case actTypes.LOAD_CUST_SHARES_TO_US_STARTED:
        return {
            ...state,
            [action.custId]: Object.assign(
                {}, 
                state[action.custId],
                {
                    shares2Us: {loading: true, err: null, shares: {}, rootTntInShr: {}}
                }
            )
        }

    case actTypes.LOAD_CUST_SHARES_TO_US_READY_OK:

        const _shares2Us={}, _rootTntInShr={}

        action.data.list.forEach(shr => {
            _shares2Us[shr.id]=shr
            _rootTntInShr[shr.tenant.id]=shr.id
        })

        return {
            ...state,
            [action.custId]: Object.assign(
                {}, 
                state[action.custId],
                {
                    shares2Us: {loading: false, err: null, shares: _shares2Us, rootTntInShr: _rootTntInShr}
                }
            )
        }
        
    case actTypes.LOAD_CUST_SHARES_TO_US_READY_PB:
        return {
            ...state,
            [action.custId]: Object.assign(
                {}, 
                state[action.custId],
                {
                    shares2Us: {loading: false, err: action.err, shares: {}, rootTntInShr: {}}
                }
            )
        }
        
    default:
        return state
    }
}
    
export default sharesReducer;