import * as actTypes from './actionTypes'

export const discardFeedback = (id) => {
    return {
        type: actTypes.FEEDBACK_DISCARD,
        id,
    }
}

export const registerFeedback = (msg, isWarning) => {
    return {
        type: actTypes.FEEDBACK_REGISTER,
        msg,
        isWarning,
    }
}