import React from 'react'
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import { IoIosInformationCircle, IoIosList, IoIosShare } from 'react-icons/io';
import TntDetailsData from './TntDetailsData';
import TntDetailsSubtenants from './TntDetailsSubtenants';
import TntBreadcrumb from './TntBreadcrumb';

function TntDetails({tntId, tntDet, tab, onChgTab, onSaveData, registerChg, changes, tenants, onChgTnt}) {

    const _chgTab = (tabKey) => {
        if(onChgTab){
            onChgTab(tabKey)
        }
    }

    //console.log(tenants)

    return (
        <React.Fragment>
            <header className="NRHead shadow">
              <TntBreadcrumb
                loading={tenants.loading || false}
                loadErr={tenants.err || ''}
                tenants={(tenants.tnts) || {}}
                onClickTnt={onChgTnt}
                selTntId={tntId || '--'}
               />
            </header>

            <div className="NRBody">
                {tab==='data' && <TntDetailsData 
                    loading={(tntDet && tntDet.loading) || false} 
                    saving={(tntDet && tntDet.saving) || false} 
                    tnt={(tntDet && tntDet.data) || {}} 
                    onSave={onSaveData} 
                    registerChg={registerChg}
                    changes={changes}
                />}
                {tab==='tenants' && <TntDetailsSubtenants 
                    loading={tenants.loading || false}
                    loadErr={tenants.err || ''}
                    tenants={(tenants.tnts) || {}}
                    onClickTnt={onChgTnt}
                    parentTntId={tntId || '--'}
                />}
                {tab==='share' && <div>Freigaben... Baustelle...</div>}
            </div>

            <footer className="NRFoot d-flex justify-content-around">
                <Button variant="link" size="sm" className="btn txt-act-color" onClick={(evnt) => {_chgTab('data')}} ><IoIosInformationCircle size={22} /><br/>Daten</Button>
                <Button disabled={tntDet && tntDet.data && !tntDet.data.container} variant="link" size="sm" className="btn txt-act-color" onClick={(evnt) => {_chgTab('tenants')}}><IoIosList size={22} /><br/>Tenants</Button>
                <Button disabled={true} variant="link" size="sm" className="btn txt-act-color" onClick={(evnt) => {_chgTab('share')}}><IoIosShare size={22} /><br/>Freigabe</Button>
            </footer>
        </React.Fragment>
    )
}

TntDetails.propTypes = {
    tntId: PropTypes.string,
    tab: PropTypes.string,
    onSaveData: PropTypes.func.isRequired,
    registerChg: PropTypes.func.isRequired,
    changes: PropTypes.object.isRequired, 
    tenants: PropTypes.object.isRequired, 
    onChgTnt: PropTypes.func.isRequired,
}

export default TntDetails