import * as actTypes from '../actions/actionTypes'
import { BugCrash } from '../../services/BugCrash'

import Axios from '../../services/Axios'
import {lbl} from '../../lbls'

export const loadRoles = () => {

    return async (dispatch, getState) => {

        try{
            dispatch({type: actTypes.LOAD_ROLES_STARTED})

            const resp = await Axios.get(`/roles`)
            if(resp && (resp.status===200)){
                return dispatch({type: actTypes.LOAD_ROLES_READY_OK, data: resp.data})
            }

            throw new Error(lbl.err_load_roles)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.LOAD_ROLES_READY_PB, err})
        }
    }
}
