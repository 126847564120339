import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux'

import { signOut } from '../../store/actions/authActions'
import Button from 'react-bootstrap/Button';

import { IoIosArrowBack, IoMdApps, IoIosPower } from 'react-icons/io';
import genPath from '../../routing/genPath';

class HeadCont extends PureComponent {

    render = () => {

        const {history, title, hideBack, showDashboard, hideLogout} = this.props

        return <header className="NRHead shadow d-flex align-items-center justify-content-between">
            {
                !hideBack && <Button variant="link" className="txt-act-color" onClick={evnt => {
                        history.go(-1)
                }}><IoIosArrowBack size={22} /></Button>
            }
            {
                showDashboard && <Button variant="link" className="txt-act-color" onClick={evnt => {
                        history.push(genPath('/', null, null))
                }}><IoMdApps size={24} /></Button>
            }
            {
                !showDashboard && hideBack && <div style={{width: 22}}>&nbsp;</div>
            }

            <div className="txt-act-color">{title}</div>

            {!hideLogout && <Button variant="link" className="txt-act-color" onClick={evnt => {
                    this.props.signOut()
                }}><IoIosPower size={22} /></Button>
            }
            {
                hideLogout && <div className="mr-3" style={{width: 22}}>&nbsp;</div>
            }
        </header>
    }
}

HeadCont.propTypes = {
    history: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    hideBack: PropTypes.bool,
    hideLogout: PropTypes.bool,
    showDashboard: PropTypes.bool,
}

/*const mapStateToProps = (state) => {
    return{
        auth: state.firebase.auth,
    }
}*/
  
const mapDispatchToProps = (dispatch) => {
    return {
        signOut: (creds) => dispatch(signOut())
    }
}

export default withRouter(connect(/*mapStateToProps*/null, mapDispatchToProps)(HeadCont))