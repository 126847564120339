import React from 'react'
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
//import { lbl } from '../../../lbls';
//import PlsWait from '../../ui/PlsWait';

export default function TntBreadcrumb({loading, loadErr, onClickTnt, tenants, selTntId}) {

    if(loading || loadErr){
        return null
        //return <PlsWait msg={lbl.load_pls_wait} />
    }

    let lvl=0
    let curId = selTntId
    let tntsPath = []

    while(lvl++<20){
        const curTnt = tenants[curId]
        if(!curTnt){
            break;
        }

        tntsPath.unshift(curTnt)
        curId=curTnt.data.parent
    }

    //console.log(tntsPath)

    return (
        <div className="d-flex align-items-center" style={{height: '100%'}}>
            {
                tntsPath.map(tnt => {
                    return <Button key={tnt.data.id} variant="link" className="txt-act-color" onClick={evnt => {onClickTnt(tnt)}}>{tnt.data.name}</Button>
                })
            }
        </div>
    )
}

TntBreadcrumb.propTypes = {
    loading: PropTypes.bool.isRequired,
    loadErr: PropTypes.string,
    onClickTnt: PropTypes.func.isRequired, 
    tenants: PropTypes.object.isRequired, 
    selTntId: PropTypes.string.isRequired,
}
