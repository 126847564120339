import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

var firebaseConfig = {
    apiKey: "AIzaSyDRC0LLLoG4gOJHC5eudSREbIY26QbooHY",
    authDomain: "inorisk-f37fc.firebaseapp.com",
    databaseURL: "https://inorisk-f37fc.firebaseio.com",
    projectId: "inorisk-f37fc",
    storageBucket: "inorisk-f37fc.appspot.com",
    messagingSenderId: "380258496921",
    appId: "1:380258496921:web:eb442a16f3c68fe6"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase