const pageOptions = {
    paginationSize: 3,
    pageStartIndex: 1,
    hideSizePerPage: true,

    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page

    sizePerPageList: [{
        text: '7', value: 7
      }
    ]
};

const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    classes: 'sel-row',
    hideSelectColumn: true,
};

export {pageOptions, selectRow}