import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import { connect } from 'react-redux'
import {parse} from 'query-string';

import PrjRolesLst from './PrjRolesLst'

import { loadProjectRoles, saveProjectUserRoles, delProjectUserRoles } from '../../../store/actions/projectRolesActions';
import { loadCustUsers } from '../../../store/actions/usersActions';
import { registerChg } from '../../../store/actions/changesActions';

import { dbg } from '../../../utils/logging'
import genPath from '../../../routing/genPath';

import PrjUsersLst from './PrjUsersLst';
import PrjUsrRoles from './PrjUsrRoles';

class PrjRolesCont extends PureComponent {

    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        custId: PropTypes.string.isRequired,
        prjId: PropTypes.string.isRequired,
        prjDet: PropTypes.object.isRequired, 
    }

    componentDidMount = () => {

        const {custId, prjId} = this.props

        dbg(`PrjRolesCont CDM: ProjektID=${prjId}`)

        this.props.loadProjectRoles(prjId)
        this.props.loadCustUsers(custId)
    };

    _onNewUsrRollen = () => {
        const {history, custId, prjId} = this.props
        const _url = genPath('/admin/projects', null, {c: custId, p: prjId, act: 'det', tab: 'roles', act1: 'new_usr_roles'})
        history.push(_url)
    }

    _onAddCustTnt = (tntId) => {
        const { custId, prjId, saveProjectMount} = this.props
        saveProjectMount(custId, prjId, tntId)
    }

    _onDelUser = (usrId) => {
        const { custId, prjId, delProjectUserRoles} = this.props
        delProjectUserRoles(custId, prjId, usrId)
    }

    _onSelUser = (usrId) => {
        const {history, custId, prjId} = this.props
        const _url = genPath('/admin/projects', null, {c: custId, p: prjId, act: 'det', tab: 'roles', act1: 'edt_usr_roles', u: usrId})
        history.push(_url)
    }

    _doSaveUsrRoles = (usrId, changes) => {
        const {history, custId, prjId, saveProjectUserRoles} = this.props
        const _url = genPath('/admin/projects', null, {c: custId, p: prjId, act: 'det', tab: 'roles'})

        saveProjectUserRoles(custId, prjId, usrId, changes, () => {
            history.push(_url)
        })
    }

    render = () => {

        const {location, prjId, prjDet, prjRoles, custUsers, roles, changes} = this.props

        dbg(`PrjRolesCont RENDER: ProjektID=${prjId}`)

        const _qs = parse(location.search) || {}
        //console.log(_qs)

        const _registerChg = (fieldName, value) => {
            const {prjId} = this.props
            this.props.registerChg(`prjUsrRoles-${prjId}-${_qs['u'] || ''}`, fieldName, value)
        }

        const _act1 = _qs['act1'] || ''

        const _loadingRoles = (roles && roles.loading) || false 
        const _loadingPrjRoles = (prjRoles && prjRoles.loading) || false 
        const _loadingPrj = (prjDet && prjDet.loading) || false 
        const _loadingCustUsers = (custUsers && custUsers.loading) || false 

        const _prj = (prjDet && prjDet.data) || {}
        
        //console.log(custUsers)
        const _usr = _qs['u'] && custUsers && !custUsers.loading && custUsers.usrs && custUsers.usrs[_qs['u']] && custUsers.usrs[_qs['u']].data
        //console.log(_usr)

        return <React.Fragment>
            {(_act1==='new_usr_roles') && <PrjUsersLst loading={_loadingPrj || _loadingCustUsers} prj={_prj} custUsers={custUsers || {}} onSelUser={this._onSelUser} />}
            {(_act1==='edt_usr_roles') && <PrjUsrRoles loading={_loadingPrjRoles || _loadingRoles || _loadingPrj} usr={_usr} usrId={_qs['u']} prj={_prj} roles={roles} prjRoles={prjRoles} actions={prjRoles && prjRoles.actions} registerChg={_registerChg} changes={changes} doSave={this._doSaveUsrRoles} />}
            {!_act1 && <PrjRolesLst loading={_loadingPrjRoles || _loadingRoles || _loadingPrj} prj={_prj} roles={roles} prjRoles={prjRoles} onSelUser={this._onSelUser} onNewUsrRollen={this._onNewUsrRollen} actions={prjRoles && prjRoles.actions} onDelUser={this._onDelUser} />}
        </React.Fragment>
    }
}

const mapStateToProps = (state, ownProps) => {

    const {custId, prjId} = ownProps
    const _qs = parse(ownProps.location.search) || {}

    return{
        prjRoles: state.projectRoles && state.projectRoles[prjId],
        roles: state.roles,
        custUsers: state.users && state.users[custId],
        changes: state.changes[`prjUsrRoles-${prjId}-${_qs['u'] || ''}`],
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        loadProjectRoles: (projId) => dispatch(loadProjectRoles(projId)),
        loadCustUsers: (custId) => dispatch(loadCustUsers(custId)),
        registerChg: (objKennung, fieldName, value) => dispatch(registerChg(objKennung, fieldName, value)),
        saveProjectUserRoles: (custId, prjId, usrId, changes, afterSaveOk) => dispatch(saveProjectUserRoles(custId, prjId, usrId, changes, afterSaveOk)),
        delProjectUserRoles: (custId, prjId, usrId) => dispatch(delProjectUserRoles(custId, prjId, usrId)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrjRolesCont))