import firebase from '../config/firebase'

export const getAccessToken = async (forceRefresh) => {

    const _curUsr = firebase.auth().currentUser

    if(!_curUsr)
        return ''

    return await _curUsr.getIdToken(forceRefresh)
}