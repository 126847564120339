import * as actTypes from '../actions/actionTypes'

const initState = {
}

const projectMountsReducer = (state = initState, action) => {

  switch(action.type){

    case actTypes.LOAD_PRJ_MOUNTS_STARTED:

        return {
            ...state,
            [action.projId]: {
                ...(state && state[action.projId]),
                loading: true,
                err: null,
                mounts: {},
            }
        }

    case actTypes.LOAD_PRJ_MOUNTS_READY_PB: 
        return {
            ...state,
            [action.projId]: {
                ...(state && state[action.projId]),
                loading: false,
                err: action.err,
            }
        }

    case actTypes.LOAD_PRJ_MOUNTS_READY_OK:

        const mounts={}
        action.data.list.forEach(mnt => {
            if(mnt.id){
                mounts[mnt.id] = Object.assign({}, state[mnt.id], {data: mnt})
            }
        })

        return {
            ...state,
            [action.projId]: {
                ...(state && state[action.projId]),
                loading: false,
                err: null,
                mounts,
            }
        }

    case actTypes.DEL_PRJ_MOUNT_STARTED:
        return {
            ...state,
            [action.projId]: {
                ...(state && state[action.projId]),

                actions: {
                    ...(state && state[action.projId] && state[action.projId].actions),

                    [action.tenantId]: {
                        ...(state && state[action.projId] && state[action.projId].actions && state[action.projId].actions[action.tenantId]),
                        del: true,
                        errDel: null,
                    }
                }
            }
        }

    case actTypes.DEL_PRJ_MOUNT_READY_OK:
        return {
            ...state,
            [action.projId]: {
                ...(state && state[action.projId]),

                actions: {
                    ...(state && state[action.projId] && state[action.projId].actions),

                    [action.tenantId]: {
                        ...(state && state[action.projId] && state[action.projId].actions && state[action.projId].actions[action.tenantId]),
                        del: false,
                        errDel: null,
                    }
                }
            }
        }

    case actTypes.DEL_PRJ_MOUNT_READY_PB:
        return {
            ...state,
            [action.projId]: {
                ...(state && state[action.projId]),

                actions: {
                    ...(state && state[action.projId] && state[action.projId].actions),

                    [action.tenantId]: {
                        ...(state && state[action.projId] && state[action.projId].actions && state[action.projId].actions[action.tenantId]),
                        del: false,
                        errDel: action.err,
                    }
                }
            }
        }

    case actTypes.SAVE_PRJ_MOUNT_STARTED:
        return {
            ...state,
            [action.projId]: {
                ...(state && state[action.projId]),

                actions: {
                    ...(state && state[action.projId] && state[action.projId].actions),

                    [action.tenantId]: {
                        ...(state && state[action.projId] && state[action.projId].actions && state[action.projId].actions[action.tenantId]),
                        saving: true,
                        errSave: null,
                    }
                }
            }
        }

    case actTypes.SAVE_PRJ_MOUNT_READY_OK:
        return {
            ...state,
            [action.projId]: {
                ...(state && state[action.projId]),

                actions: {
                    ...(state && state[action.projId] && state[action.projId].actions),

                    [action.tenantId]: {
                        ...(state && state[action.projId] && state[action.projId].actions && state[action.projId].actions[action.tenantId]),
                        saving: false,
                        errSave: null,
                    }
                }
            }
        }

    case actTypes.SAVE_PRJ_MOUNT_READY_PB:
        return {
            ...state,
            [action.projId]: {
                ...(state && state[action.projId]),

                actions: {
                    ...(state && state[action.projId] && state[action.projId].actions),

                    [action.tenantId]: {
                        ...(state && state[action.projId] && state[action.projId].actions && state[action.projId].actions[action.tenantId]),
                        saving: false,
                        errSave: action.err,
                    }
                }
            }
        }

    default:
        return state
  }
}

export default projectMountsReducer;