import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import { connect } from 'react-redux'
import {parse} from 'query-string';

import PrjMountsLst from './PrjMountsLst'

import { loadProjectMounts, saveProjectMount, delProjectMount } from '../../../store/actions/projectMountsActions';
import { loadCustTenants } from '../../../store/actions/tenantsActions';

import { dbg } from '../../../utils/logging'
import genPath from '../../../routing/genPath';
import PrjMountCreate from './PrjMountCreate';

class PrjMountsCont extends PureComponent {

    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        custId: PropTypes.string.isRequired,
        prjId: PropTypes.string.isRequired,
        prjDet: PropTypes.object.isRequired, 
    }

    componentDidMount = () => {

        const {custId, prjId} = this.props

        dbg(`PrjMountsCont CDM: ProjektID=${prjId}`)

        this.props.loadProjectMounts(prjId)
        this.props.loadCustTenants(custId)
    };

    _onSelMnt = (mnt) => {
        const {history, custId, prjId} = this.props
        const _url = genPath('/admin/projects', null, {c: custId, p: prjId, act: 'det', tab: 'mounts', act1: 'edt_mnt', mnt: mnt.data.id})
        //console.log(_url)
        history.push(_url)
    }

    _onCreateMnt = () => {
        const {history, custId, prjId} = this.props
        const _url = genPath('/admin/projects', null, {c: custId, p: prjId, act: 'det', tab: 'mounts', act1: 'create_mnt'})
        history.push(_url)
    }

    _onAddCustTnt = (tntId) => {
        const { custId, prjId, saveProjectMount} = this.props
        saveProjectMount(custId, prjId, tntId)
    }

    _onDelMnt = (tntId) => {
        const { custId, prjId, delProjectMount} = this.props
        delProjectMount(custId, prjId, tntId)
    }

    render = () => {

        const {location, prjId, prjDet, mounts, custTnts} = this.props

        dbg(`PrjMountsCont RENDER: ProjektID=${prjId}`)

        const _qs = parse(location.search) || {}
        //console.log(_qs)

        const _act1 = _qs['act1'] || ''

        const _loadingMounts = (mounts && mounts.loading) || false 
        const _loadingPrj = (prjDet && prjDet.loading) || false 
        const _loadingCustTnts = (custTnts && custTnts.loading) || false 

        const _prj = (prjDet && prjDet.data) || {}

        return <React.Fragment>
            {(_act1==='create_mnt') && <PrjMountCreate loading={_loadingPrj || _loadingCustTnts} prj={_prj} custTnts={custTnts} addCustTnt={this._onAddCustTnt} actions={mounts && mounts.actions} />}
            {!_act1 && <PrjMountsLst loading={_loadingMounts || _loadingPrj || _loadingCustTnts} prj={_prj} mounts={mounts} custTnts={custTnts} onSelMnt={this._onSelMnt} onCreateMnt={this._onCreateMnt} actions={mounts && mounts.actions} onDelMnt={this._onDelMnt} />}
        </React.Fragment>
    }
}

const mapStateToProps = (state, ownProps) => {

    const {custId, prjId} = ownProps

    return{
        mounts: state.projectMounts && state.projectMounts[prjId],
        custTnts: state.tenants && state.tenants[custId],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadProjectMounts: (prjId) => dispatch(loadProjectMounts(prjId)),
        loadCustTenants: (custId) => dispatch(loadCustTenants(custId)),
        saveProjectMount: (custId, prjId, tenantId) => dispatch(saveProjectMount(custId, prjId, tenantId)),
        delProjectMount: (custId, prjId, tenantId) => dispatch(delProjectMount(custId, prjId, tenantId)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrjMountsCont))