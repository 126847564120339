import React, {useState} from 'react'
import PlsWait from '../../ui/PlsWait';
import { lbl } from '../../../lbls';

import Button from 'react-bootstrap/Button';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { IoIosAddCircleOutline } from 'react-icons/io';
import HeadCont from '../../ui/HeadCont';
import { pageOptions, selectRow } from '../../ui/TblUtils';

function PrjLst({projects, onChgPrj, addPrj, delPrj, prjId}) {
    const [nbRec, setNbRec] = useState('')

    //console.log(projects)
    
    const _selPrjs = {}

    if(!projects.loading && projects.prjs){
        Object.values(projects.prjs).forEach(p => {
            if(p.data && !p.err){
                _selPrjs[p.data.id]=p
            }
        })
    }

    const _prepPrjs = Object.values(_selPrjs).map(p => {
        return {
            ...p,
            id: p.data && p.data.id,
            name: p.data && p.data.name,
        }
    })

    const _handleDataChange = (test) => {
        setNbRec(test.dataSize)
    }

    const _selectRow = {
        ...selectRow,
        selected: [prjId],
    };
    const _defaultSorted = [{
        dataField: 'name',
        order: 'asc'
    }];

    const { SearchBar } = Search;

    return (
        <React.Fragment>
            <HeadCont title={lbl.title_prj_lst} hideBack={true} showDashboard={true} hideLogout={true} />

            <div className="NRBody">
                {
                    projects.loading && <PlsWait msg={lbl.load_pls_wait} />
                }

                <div className="p-3">
                    {
                        <React.Fragment>
                            <ToolkitProvider
                                keyField="id"
                                data={_prepPrjs}
                                columns={[
                                    {
                                        dataField: 'name',
                                        isDummyField: true,
                                        sort: true,
                                        text: 'Name',
                                        formatter: (cellContent, row) => {
                                            return `${row['name']}`
                                        },
                                        events: {
                                            onClick: (e, column, columnIndex, row, rowIndex) => {
                                                onChgPrj(row)
                                            }
                                        }
                                    }
                                ]}
                                search
                            >
                            {
                                props => (
                                    <React.Fragment>
                                        <SearchBar 
                                            className='searchBox'
                                            delay={700}
                                            placeholder={lbl.search_prj}
                                            { ...props.searchProps } 
                                        />

                                        <BootstrapTable
                                            bootstrap4
                                            striped
                                            hover
                                            headerClasses="d-none"
                                            defaultSorted={_defaultSorted}
                                            noDataIndication={(projects.loading)?lbl.load_pls_wait:lbl.no_data}
                                            pagination={ paginationFactory(pageOptions) }
                                            selectRow={_selectRow}
                                            onDataSizeChange={ _handleDataChange }
                                            { ...props.baseProps }
                                        />
                                    </React.Fragment>
                                )
                            }
                            </ToolkitProvider>
                            
                        </React.Fragment>
                    }
                </div>
            </div>

            <footer className="NRFoot d-flex justify-content-around align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="offset-3 col-6 d-flex justify-content-center align-self-center">{nbRec} {lbl.entries}</div>
                        <div className="col-3 d-flex justify-content-end">
                            <Button variant="link" className="btn txt-act-color" onClick={
                                (evnt) => {
                                    if(addPrj){
                                        addPrj()
                                    }
                                }
                            }><IoIosAddCircleOutline size={30} /></Button>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}

export default PrjLst