import React from 'react'
import PropTypes from 'prop-types';
import PlsWait from '../../ui/PlsWait';
import { lbl } from '../../../lbls';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { pageOptions, selectRow } from '../../ui/TblUtils';

import { dbg } from '../../../utils/logging';
import Button from 'react-bootstrap/Button';
import { IoIosAddCircleOutline } from 'react-icons/io';
import ContextInfo from '../../ui/ContextInfo';

function userName(usr){

    if(!usr || !usr.data){
        return '-'
    }

    const _data = usr.data
    if(_data['name'] && _data['email']){
        return `${_data['name']} (${_data['email']})`
    }

    if(_data['name']){
        return _data['name']
    }

    return _data['email']
}

function PrjUsersLst({loading, prj, custUsers, onSelUser}) {

    dbg(`PrjUsersLst RENDER: ProjektID=${prj.id}`)

    //console.log(custUsers)

    const _usersPrep = (loading || !custUsers || !custUsers.usrs)?[]:Object.values(custUsers.usrs).map((usr) => {
        return {
            id: usr.data.id,
            name: userName(usr),
        }
    })

    //console.log(_usersPrep)

    const _selectRow = {
        ...selectRow,
        selected: [],
    };
    const _defaultSorted = [{
        dataField: 'name',
        order: 'asc'
    }];

    const { SearchBar } = Search;

    return <React.Fragment>

        <ContextInfo infos={[
            {left: lbl.prj, right: prj && prj.name},
        ]} />

        { loading && <PlsWait msg={lbl.load_pls_wait} /> }

        <div className="pl-3 pr-3">
            <ToolkitProvider
                    keyField="id"
                    data={_usersPrep}
                    columns={[
                        {
                            dataField: 'name',
                            sort: true,
                            text: 'Name',
                            events: {
                                onClick: (e, column, columnIndex, row, rowIndex) => {
                                    onSelUser(row['id'])
                                }
                            },
                            style: { verticalAlign: 'middle' },
                        },
                        {
                            dataField: 'id',
                            searchable: false,
                            isDummyField: true,
                            text: '+',
                            style: {width: 50},
                            formatter: (cellContent, row) => {
                                return <div className="d-flex justify-content-center">

                                    <Button variant="link" className="btn txt-act-color" onClick={
                                        (evnt) => {
                                            onSelUser(row['id'])
                                        }
                                    }><IoIosAddCircleOutline size={30} /></Button>
                                </div>
                            }
                        }
                    ]}
                    search
                >
                    {
                        props => (
                            <React.Fragment>
                                <SearchBar 
                                    className='searchBox'
                                    delay={700}
                                    placeholder={lbl.search_usr}
                                    { ...props.searchProps } 
                                />

                                <BootstrapTable
                                    bootstrap4
                                    striped
                                    hover
                                    headerClasses="d-none"
                                    defaultSorted={_defaultSorted}
                                    noDataIndication={(loading)?lbl.load_pls_wait:lbl.no_data}
                                    pagination={ paginationFactory(pageOptions) }
                                    selectRow={_selectRow}
                                    { ...props.baseProps }
                                />
                            </React.Fragment>
                        )
                    }
                </ToolkitProvider>
        </div>
    </React.Fragment>
}

PrjUsersLst.propTypes = {
    loading: PropTypes.bool.isRequired,
    prj: PropTypes.object.isRequired,
    custUsers: PropTypes.object.isRequired,
    onSelUser: PropTypes.func.isRequired,
}

export default PrjUsersLst