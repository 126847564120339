import React, {PureComponent} from 'react'
import { Link, Redirect } from 'react-router-dom';

import genPath from '../routing/genPath';
import { connect } from 'react-redux'
import PlsWait from './ui/PlsWait';
import { lbl } from '../lbls';
import { signOut } from '../store/actions/authActions';
import Button from 'react-bootstrap/Button';

class Dashboard extends PureComponent {

    render = () => {

        const {myCust, auth} = this.props

        if(!auth || !auth.uid){
            return <Redirect to='/login' />
        }

        if(myCust.loading){
            return <PlsWait msg={lbl.load_pls_wait} />
        }

        return (
            <div>
                {
                    myCust.list && myCust.list.map(cust => {
                        return <React.Fragment key={`adm_${cust.id}`}>
                            <div key={`adm_tnts_${cust.id}`} className="m-3">
                                <Link to={genPath('/admin/tenants', null, {c: cust.id})}>Admin -> Tenants [Konto: {cust.name}]</Link>
                            </div>
                            <div key={`adm_prj_${cust.id}`} className="m-3">
                                <Link to={genPath('/admin/projects', null, {c: cust.id})}>Admin -> Projekte [Konto: {cust.name}]</Link>
                            </div>
                        </React.Fragment>
                    })
                }

                <div className="m-3">
                    <Button variant="outline-primary" className="txt-act-color" onClick={(evnt) => {
                        this.props.signOut()
                    }}>Abmelden</Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return{
        auth: state.firebase.auth,
        myCust: state.permissions && state.permissions.myCust,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
