const _baseClassNames = 'btn txt-act-color'

const _calcClassNames = (tabs, selTab) => {

    return tabs.reduce((prv, cur) => {

        if(selTab===cur){
            prv[cur]=`${_baseClassNames} selected-tab`
        }
        else{
            prv[cur]=`${_baseClassNames}`
        }

        return prv
    }, {})
}

export {
    _calcClassNames as calcClassNames,
}