import React from 'react'
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import { IoIosInformationCircle, IoIosList, IoIosShare } from 'react-icons/io';

import PrjDetailsData from './PrjDetailsData';
import PrjMountsCont from './PrjMountsCont';
import PrjRolesCont from './PrjRolesCont';

import HeadCont from '../../ui/HeadCont';
import { lbl } from '../../../lbls';

import { calcClassNames } from '../../ui/TabUtils'

function PrjDetails({history, custId, prjId, custDet, prjDet, tab, onChgTab, onSaveData, registerChg, changes, projects, onChgPrj, delPrj, qs}) {

    const _chgTab = (tabKey) => {
        if(onChgTab){
            onChgTab(tabKey)
        }
    }

    const _getTitle = () => {
        //console.log(qs)

        if(qs['tab']==='data'){
            return lbl.prj_data
        }
        else if(qs['tab']==='mounts'){
            if(qs['act1']==='create_mnt'){
                return lbl.prj_mnt_create    
            }

            return lbl.prj_mnts_lst
        }
        else if(qs['tab']==='roles'){

            if(qs['act1']==='new_usr_roles'){
                return lbl.usr_roles_choose_usr    
            }

            return lbl.usr_roles_lst
        }

        return "In Bearbeitung"
    }

    //console.log(custDet)
    //console.log(projects)
    
    const _tabClassNames = calcClassNames(['data', 'mounts', 'roles'], tab)

    return (
        <React.Fragment>
            <HeadCont title={_getTitle(qs)} />

            <div className="NRBody">
                {tab==='data' && <PrjDetailsData 
                    loading={(prjDet && prjDet.loading) || false} 
                    saving={(prjDet && prjDet.saving) || false} 
                    deleting={(prjDet && prjDet.deleting) || false} 

                    prj={(prjDet && prjDet.data) || {}} 
                    onSave={onSaveData} 
                    registerChg={registerChg}
                    changes={changes}
                    delPrj={delPrj}
                />}
                {tab==='mounts' && <PrjMountsCont
                    custId={custId}
                    prjId={prjId}
                    prjDet={prjDet || {}}
                />}
                {tab==='roles' && <PrjRolesCont
                    custId={custId}
                    prjId={prjId}
                    prjDet={prjDet || {}}
                />}
            </div>

            <footer className="NRFoot d-flex justify-content-around">
                <Button variant="link" size="sm" className={_tabClassNames.data} onClick={(evnt) => {_chgTab('data')}} ><IoIosInformationCircle size={22} /><br/>{lbl.tab_data}</Button>
                <Button variant="link" size="sm" className={_tabClassNames.mounts} onClick={(evnt) => {_chgTab('mounts')}}><IoIosList size={22} /><br/>{lbl.tab_mounts}</Button>
                <Button variant="link" size="sm" className={_tabClassNames.roles} onClick={(evnt) => {_chgTab('roles')}}><IoIosShare size={22} /><br/>{lbl.tab_roles}</Button>
            </footer>
        </React.Fragment>
    )
}

PrjDetails.propTypes = {
    history: PropTypes.object.isRequired,
    custId: PropTypes.string,
    prjId: PropTypes.string,
    tab: PropTypes.string,
    onSaveData: PropTypes.func.isRequired,
    registerChg: PropTypes.func.isRequired,
    changes: PropTypes.object.isRequired, 
    projects: PropTypes.object.isRequired, 
    onChgPrj: PropTypes.func.isRequired,
}

export default PrjDetails
