import React, {PureComponent} from 'react'
//import PropTypes from 'prop-types'
import Toast from 'react-bootstrap/Toast'
import { connect } from 'react-redux'
import { discardFeedback } from '../../store/actions/feedbackActions';

function SuccessInfo({msg, onClose}) {

    return <Toast onClose={onClose} show={true} delay={3000} autohide>
            <Toast.Header>
                <div className="mr-auto text-success">{msg}</div>
            </Toast.Header>
        </Toast>
}

function DangerInfo({msg, onClose}) {

    return <Toast onClose={onClose} show={true}>
            <Toast.Header>
                <div className="mr-auto text-danger">{msg}</div>
            </Toast.Header>
        </Toast>
}

class ActionFeedback extends PureComponent {
    /*static propTypes = {
        xs: PropTypes.bool,
    }*/

    _onClose = (id) => {
        this.props.discardFeedback(id)
    }

    render = () => {

        const _msgs = Object.values(this.props.msgs)

        if(!_msgs || (_msgs.length===0)){
            return null
        }

        return (
            <div className="feedbackArea">
                {
                    _msgs.map((msg) => {
                        return (msg.isWarning)?(<DangerInfo key={msg.id} msg={msg.msg} onClose={() => this._onClose(msg.id)} />):(<SuccessInfo key={msg.id} msg={msg.msg} onClose={() => this._onClose(msg.id)} />)
                    })
                }
            </div>
        )    
    }
}

const mapStateToProps = (state) => {  
    return{
      msgs: state.feedback,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        discardFeedback: (id) => dispatch(discardFeedback(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionFeedback)