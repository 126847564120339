import React from 'react'
import PropTypes from 'prop-types';
import PlsWait from '../../ui/PlsWait';
import { lbl } from '../../../lbls';

import Form from 'react-bootstrap/Form';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { selectRow } from '../../ui/TblUtils';

import { dbg } from '../../../utils/logging';
import Button from 'react-bootstrap/Button';
import ContextInfo from '../../ui/ContextInfo';

function _getRoleChecked(changes, usrRoles, roleId) {

    if(changes && changes[roleId]){
        return changes[roleId].val
    }

    return (usrRoles && usrRoles[roleId]) || false
}

function userName(usr){

    if(!usr){
        return '-'
    }

    const _data = usr
    /*if(_data['name'] && _data['email']){
        return `${_data['name']} (${_data['email']})`
    }*/

    if(_data['name']){
        return _data['name']
    }

    return _data['email']
}

function PrjUsrRoles({loading, usr, usrId, prj, roles, prjRoles, changes, registerChg, actions, doSave}) {

    dbg(`PrjUsrRoles RENDER: ProjektID=${prj.id}`)

    //console.log(changes)
    //console.log(roles)
    //console.log(prjRoles)
    //console.log(actions)

    const _usrRoles = prjRoles && prjRoles.usrRoles && prjRoles.usrRoles[usrId] && prjRoles.usrRoles[usrId].data 
                        && prjRoles.usrRoles[usrId].data.roles
                        && prjRoles.usrRoles[usrId].data.roles.reduce((prv, cur) => {
                            prv[cur]=true
                            return prv
                        }, {})

    //console.log(_usrRoles)

    const _rolesPrep = (loading || !roles || !roles.roles)?[]:Object.values(roles.roles).sort((r1, r2) => r1.name>r2.name).map((role) => {
        return {
            ...role,
            checked: _getRoleChecked(changes, _usrRoles, role.id),
        }
    })
    //console.log(_rolesPrep)

    const _selectRow = {
        ...selectRow,
        selected: [],
    };
    const _defaultSorted = [{
        dataField: 'name',
        order: 'asc'
    }];

    const _handleChkChange = (evnt) => {
        const {name, checked} = evnt.target
        registerChg(name, checked)
    }

    return <React.Fragment>

        <ContextInfo infos={[
            {left: lbl.prj, right: prj && prj.name},
            {left: lbl.usr, right: userName(usr)},
        ]} />

        { loading && <PlsWait msg={lbl.load_pls_wait} /> }
        { (actions && actions[usrId] && actions[usrId].saving) && <PlsWait msg={lbl.save_pls_wait} /> }

        <div className="pl-3 pr-3">
            <ToolkitProvider
                    keyField="id"
                    data={_rolesPrep}
                    columns={[
                        {
                            dataField: 'name',
                            sort: true,
                            text: 'Name',
                            formatter: (cellContent, row) => {
                                return <React.Fragment>
                                <p className='lstItmTxt'>{row['name']}</p>
                                <p className='lstItmDesc'>{row['desc']}</p>
                            </React.Fragment>
                            },
                        },
                        {
                            dataField: 'id',
                            searchable: false,
                            isDummyField: true,
                            text: '+',
                            style: {width: 50},
                            formatter: (cellContent, row) => {
                                return <div className="d-flex justify-content-center">
                                    <Form.Control name={row['id']} type="checkbox" 
                                        checked={row['checked']}
                                        onChange={_handleChkChange} />
                                </div>
                            }
                        }
                    ]}
                >
                    {
                        props => (
                            <React.Fragment>
                                <BootstrapTable
                                    bootstrap4
                                    striped
                                    hover
                                    headerClasses="d-none"
                                    defaultSorted={_defaultSorted}
                                    noDataIndication={(loading)?lbl.load_pls_wait:lbl.no_data}
                                    selectRow={_selectRow}
                                    { ...props.baseProps }
                                />
                            </React.Fragment>
                        )
                    }
                </ToolkitProvider>

            <div className="d-flex justify-content-between dividerTop mt-3 pt-2">
                <Button variant="outline-primary" size="sm" type="button" onClick={(evnt) => {
                    doSave(usrId, changes)
                }}>{lbl.save}</Button>
            </div>
        </div>
    </React.Fragment>
}

PrjUsrRoles.propTypes = {
    loading: PropTypes.bool.isRequired,
    usr: PropTypes.object,
    prj: PropTypes.object.isRequired,
    doSave: PropTypes.func.isRequired,
}

export default PrjUsrRoles