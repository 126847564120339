import * as actTypes from './actionTypes'

export const registerChg = (objKennung, fieldName, newValue) => {
    return {
        type: actTypes.CHG_REGISTER,
        objKennung,
        fieldName,
        newValue,
    }
}

export const discardChgs = (objKennung) => {
    return {
        type: actTypes.CHG_REGISTER,
        objKennung,
    }
}