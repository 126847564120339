import * as actTypes from '../actions/actionTypes'

const initState = {
  myCust: {loading: false, list: [], err: null, lastLoad: -1},
}

const permissionsReducer = (state = initState, action) => {

  switch(action.type){

    case actTypes.LOAD_MY_CUST_STARTED:
        return {
            ...state,
            myCust: {loading: true, err: null, list: []},
        }

    case actTypes.LOAD_MY_CUST_READY_PB:
        return {
            ...state,
            myCust: {loading: false, err: action.err},
        }

    case actTypes.LOAD_MY_CUST_READY_OK:
        return {
            ...state,
            myCust: {loading: false, err: null, list: action.data.list, lastLoad: Date.now()},
        }

    default:
        return state
    }
}

export default permissionsReducer;