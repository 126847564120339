import * as actTypes from './actionTypes'
import { BugCrash } from '../../services/BugCrash'
import {loadMyCustomers} from './permissionsActions'

export const signIn = (credentials) => {
    return async (dispatch, getState, {getFirebase}) => {

        dispatch({type: actTypes.AUTH_LOGIN_STARTED})

        const firebase = getFirebase();

        try{
            await firebase.auth().signInWithEmailAndPassword(
                    credentials.email,
                    credentials.password
            )

            dispatch({type: actTypes.AUTH_LOGIN_READY_OK})
            dispatch(loadMyCustomers())
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.AUTH_LOGIN_READY_PB, err})
        }  
    }
}

export const signOut = () => {
    return async (dispatch, getState, {getFirebase}) => {

        dispatch({type: actTypes.AUTH_LOGOUT_STARTED})

        const firebase = getFirebase();

        try{
            await firebase.auth().signOut()
            dispatch({type: actTypes.AUTH_LOGOUT_READY_OK})
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.AUTH_LOGOUT_READY_PB, err})
        }
    }
}