import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux'
import configureStore  from './store/setupStore'
import { PersistGate } from 'redux-persist/integration/react'

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import './scss/custom.scss';

const {store, persistor} = configureStore({});

store.firebaseAuthIsReady.then(() => {

    ReactDOM.render(<Provider store={store}><PersistGate loading={null} persistor={persistor}> <App /></PersistGate></Provider>, document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();    
})