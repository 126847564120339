export const AUTH_LOGIN_STARTED = 'AUTH_LOGIN_STARTED';
export const AUTH_LOGIN_READY_OK = 'AUTH_LOGIN_READY_OK';
export const AUTH_LOGIN_READY_PB = 'AUTH_LOGIN_READY_PB';

export const AUTH_LOGOUT_STARTED = 'AUTH_LOGOUT_STARTED';
export const AUTH_LOGOUT_READY_OK = 'AUTH_LOGOUT_READY_OK';
export const AUTH_LOGOUT_READY_PB = 'AUTH_LOGOUT_READY_PB';

export const LOAD_MY_CUST_STARTED = 'LOAD_MY_CUST_STARTED';
export const LOAD_MY_CUST_READY_OK = 'LOAD_MY_CUST_READY_OK';
export const LOAD_MY_CUST_READY_PB = 'LOAD_MY_CUST_READY_PB';

export const LOAD_TNT_STARTED = 'LOAD_TNT_STARTED';
export const LOAD_TNT_READY_OK = 'LOAD_TNT_READY_OK';
export const LOAD_TNT_READY_PB = 'LOAD_TNT_READY_PB';
//export const UPD_TNT_FROM_CHANGES = 'UPD_TNT_FROM_CHANGES';

export const SAVE_TNT_STARTED = 'SAVE_TNT_STARTED';
export const SAVE_TNT_READY_OK = 'SAVE_TNT_READY_OK';
export const SAVE_TNT_READY_PB = 'SAVE_TNT_READY_PB';

export const LOAD_CUST_TNTS_STARTED = 'LOAD_CUST_TNTS_STARTED';
export const LOAD_CUST_TNTS_READY_OK = 'LOAD_CUST_TNTS_READY_OK';
export const LOAD_CUST_TNTS_READY_PB = 'LOAD_CUST_TNTS_READY_PB';

export const LOAD_CUST_SHARES_TO_US_STARTED = 'LOAD_CUST_SHARES_TO_US_STARTED';
export const LOAD_CUST_SHARES_TO_US_READY_OK = 'LOAD_CUST_SHARES_TO_US_READY_OK';
export const LOAD_CUST_SHARES_TO_US_READY_PB = 'LOAD_CUST_SHARES_TO_US_READY_PB';

export const CHG_REGISTER = 'CHG_REGISTER';
export const CHG_DISCARD = 'CHG_DISCARD';

export const FEEDBACK_REGISTER = 'FEEDBACK_REGISTER';
export const FEEDBACK_DISCARD = 'FEEDBACK_DISCARD';

export const LOAD_CUST_PRJS_STARTED = 'LOAD_CUST_PRJS_STARTED';
export const LOAD_CUST_PRJS_READY_OK = 'LOAD_CUST_PRJS_READY_OK';
export const LOAD_CUST_PRJS_READY_PB = 'LOAD_CUST_PRJS_READY_PB';

export const ADD_PRJ = 'ADD_PRJ';
export const LOAD_PRJ_STARTED = 'LOAD_PRJ_STARTED';
export const LOAD_PRJ_READY_OK = 'LOAD_PRJ_READY_OK';
export const LOAD_PRJ_READY_PB = 'LOAD_PRJ_READY_PB';

export const SAVE_PRJ_STARTED = 'SAVE_PRJ_STARTED';
export const SAVE_PRJ_READY_OK = 'SAVE_PRJ_READY_OK';
export const SAVE_PRJ_READY_PB = 'SAVE_PRJ_READY_PB';

export const DEL_PRJ_STARTED = 'DEL_PRJ_STARTED';
export const DEL_PRJ_READY_OK = 'DEL_PRJ_READY_OK';
export const DEL_PRJ_READY_PB = 'DEL_PRJ_READY_PB';

export const LOAD_PRJ_MOUNTS_STARTED = 'LOAD_PRJ_MOUNTS_STARTED';
export const LOAD_PRJ_MOUNTS_READY_OK = 'LOAD_PRJ_MOUNTS_READY_OK';
export const LOAD_PRJ_MOUNTS_READY_PB = 'LOAD_PRJ_MOUNTS_READY_PB';

export const SAVE_PRJ_MOUNT_STARTED = 'SAVE_PRJ_MOUNT_STARTED';
export const SAVE_PRJ_MOUNT_READY_OK = 'SAVE_PRJ_MOUNT_READY_OK';
export const SAVE_PRJ_MOUNT_READY_PB = 'SAVE_PRJ_MOUNT_READY_PB';

export const DEL_PRJ_MOUNT_STARTED = 'DEL_PRJ_MOUNT_STARTED';
export const DEL_PRJ_MOUNT_READY_OK = 'DEL_PRJ_MOUNT_READY_OK';
export const DEL_PRJ_MOUNT_READY_PB = 'DEL_PRJ_MOUNT_READY_PB';

export const LOAD_CUST_USERS_STARTED = 'LOAD_CUST_USERS_STARTED';
export const LOAD_CUST_USERS_READY_OK = 'LOAD_CUST_USERS_READY_OK';
export const LOAD_CUST_USERS_READY_PB = 'LOAD_CUST_USERS_READY_PB';

export const LOAD_PRJ_ROLES_STARTED = 'LOAD_PRJ_ROLES_STARTED';
export const LOAD_PRJ_ROLES_READY_OK = 'LOAD_PRJ_ROLES_READY_OK';
export const LOAD_PRJ_ROLES_READY_PB = 'LOAD_PRJ_ROLES_READY_PB';

export const LOAD_ROLES_STARTED = 'LOAD_ROLES_STARTED';
export const LOAD_ROLES_READY_OK = 'LOAD_ROLES_READY_OK';
export const LOAD_ROLES_READY_PB = 'LOAD_ROLES_READY_PB';

export const SAVE_PRJ_USR_ROLES_STARTED = 'SAVE_PRJ_USR_ROLES_STARTED';
export const SAVE_PRJ_USR_ROLES_READY_OK = 'SAVE_PRJ_USR_ROLES_READY_OK';
export const SAVE_PRJ_USR_ROLES_READY_PB = 'SAVE_PRJ_USR_ROLES_READY_PB';

export const DEL_PRJ_USR_ROLES_STARTED = 'DEL_PRJ_USR_ROLES_STARTED';
export const DEL_PRJ_USR_ROLES_READY_OK = 'DEL_PRJ_USR_ROLES_READY_OK';
export const DEL_PRJ_USR_ROLES_READY_PB = 'DEL_PRJ_USR_ROLES_READY_PB';
