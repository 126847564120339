import * as actTypes from './actionTypes'
import { BugCrash } from '../../services/BugCrash'
import Axios from '../../services/Axios'
import {lbl} from '../../lbls'

export const loadMyCustomers = () => {
    return async (dispatch, getState) => {

        try{
            dispatch({type: actTypes.LOAD_MY_CUST_STARTED})

            const resp = await Axios.get('/my/customers')
            if(resp && (resp.status===200)){
                return dispatch({type: actTypes.LOAD_MY_CUST_READY_OK, data: resp.data})
            }

            throw new Error(lbl.err_load_my_cust)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.LOAD_MY_CUST_READY_PB, err})
        }
    }
}