import * as actTypes from '../actions/actionTypes'
import { getUuid } from '../../services/IdGenerator';

const initState = {}

const feedbackReducer = (state = initState, action) => {

    switch(action.type){

        case actTypes.FEEDBACK_REGISTER:

            const id=getUuid()

            return {
                ...state,
                [id]: {
                    msg: action.msg,
                    isWarning: action.isWarning,
                    id,
                    createdAt: Date.now(),
                }
            }

        case actTypes.FEEDBACK_DISCARD:
            return Object.keys(state).reduce(
                    (obj, key) => {
                        if (key !== action.id) {
                            obj[key] = state[key]
                        }
                        return obj
                    },
                    {}
                )

        default:
            return state
    }
}

export default feedbackReducer;