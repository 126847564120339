import * as actTypes from '../actions/actionTypes'

const initState = {
}

const projectRolesReducer = (state = initState, action) => {

    switch(action.type){

        case actTypes.LOAD_PRJ_ROLES_STARTED:

            return {
                ...state,
                [action.projId]: {
                    ...(state && state[action.projId]),
                    loading: true,
                    err: null,
                    usrRoles: {},
                }
            }

        case actTypes.LOAD_PRJ_ROLES_READY_PB: 
            return {
                ...state,
                [action.projId]: {
                    ...(state && state[action.projId]),
                    loading: false,
                    err: action.err,
                }
            }
    
        case actTypes.LOAD_PRJ_ROLES_READY_OK:

            const usrRoles={}
            action.data.list.forEach(itm => {
                if(itm.id){
                    usrRoles[itm.id] = Object.assign({}, state[itm.id], {data: itm})
                }
            })
    
            return {
                ...state,
                [action.projId]: {
                    ...(state && state[action.projId]),
                    loading: false,
                    err: null,
                    usrRoles
                }
            }

        case actTypes.SAVE_PRJ_USR_ROLES_STARTED:

            return {
                ...state,
                [action.projId]: {
                    ...(state && state[action.projId]),
    
                    actions: {
                        ...(state && state[action.projId] && state[action.projId].actions),

                        [action.usrId]: {
                            ...(state && state[action.projId] && state[action.projId].actions && state[action.projId].actions[action.usrId]),
                            saving: true,
                            errSave: null,
                        }
                    }
                }
            }

        case actTypes.SAVE_PRJ_USR_ROLES_READY_OK:

            return {
                ...state,
                [action.projId]: {
                    ...(state && state[action.projId]),
    
                    actions: {
                        ...(state && state[action.projId] && state[action.projId].actions),

                        [action.usrId]: {
                            ...(state && state[action.projId] && state[action.projId].actions && state[action.projId].actions[action.usrId]),
                            saving: false,
                            errSave: null,
                        }
                    }
                }
            }

        case actTypes.SAVE_PRJ_USR_ROLES_READY_PB:
            return {
                ...state,
                [action.projId]: {
                    ...(state && state[action.projId]),
    
                    actions: {
                        ...(state && state[action.projId] && state[action.projId].actions),

                        [action.usrId]: {
                            ...(state && state[action.projId] && state[action.projId].actions && state[action.projId].actions[action.usrId]),
                            saving: false,
                            errSave: action.err,
                        }
                    }
                }
            }

        case actTypes.DEL_PRJ_USR_ROLES_STARTED:

            return {
                ...state,
                [action.projId]: {
                    ...(state && state[action.projId]),
    
                    actions: {
                        ...(state && state[action.projId] && state[action.projId].actions),

                        [action.usrId]: {
                            ...(state && state[action.projId] && state[action.projId].actions && state[action.projId].actions[action.usrId]),
                            deleting: true,
                            errDelete: null,
                        }
                    }
                }
            }
        
        case actTypes.DEL_PRJ_USR_ROLES_READY_OK:

            return {
                ...state,
                [action.projId]: {
                    ...(state && state[action.projId]),
    
                    actions: {
                        ...(state && state[action.projId] && state[action.projId].actions),

                        [action.usrId]: {
                            ...(state && state[action.projId] && state[action.projId].actions && state[action.projId].actions[action.usrId]),
                            deleting: false,
                            errDelete: null,
                        }
                    }
                }
            }

        case actTypes.DEL_PRJ_USR_ROLES_READY_PB:
            
            return {
                ...state,
                [action.projId]: {
                    ...(state && state[action.projId]),
    
                    actions: {
                        ...(state && state[action.projId] && state[action.projId].actions),

                        [action.usrId]: {
                            ...(state && state[action.projId] && state[action.projId].actions && state[action.projId].actions[action.usrId]),
                            deleting: false,
                            errDelete: action.err,
                        }
                    }
                }
            }

        default:
            return state
    }
}

export default projectRolesReducer;