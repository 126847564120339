import * as actTypes from '../actions/actionTypes'

const initState = {
}

const usersReducer = (state = initState, action) => {

    switch(action.type){

        case actTypes.LOAD_CUST_USERS_STARTED:

            return {
                ...state,
                [action.custId]: {
                    ...(state && state[action.custId]),
                    loading: true,
                    err: null,
                    users: {},
                }
            }

        case actTypes.LOAD_CUST_USERS_READY_OK:

            return {
                ...state,
                [action.custId]: {
                    ...(state && state[action.custId]),
                    loading: false,
                    err: null,
                    usrs: (action.data.list && action.data.list.reduce((prvVal, curObj) => {
                        return {
                            ...prvVal,
                            [curObj.id]: {data: curObj},
                        }
                    }, {})) || {},
                }
            }

        case actTypes.LOAD_CUST_USERS_READY_PB:
            return {
                ...state,
                [action.custId]: {
                    ...(state && state[action.custId]),
                    loading: false,
                    err: action.err,
                    users: {},
                }
            }

        default:
            return state
    }
}

export default usersReducer;