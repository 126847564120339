import authReducer from './authReducer'
import permissionsReducer from './permissionsReducer'
import tenantsReducer from './tenantsReducer'
import changesReducer from './changesReducer'
import sharesReducer from './sharesReducer'
import feedbackReducer from './feedbackReducer'
import projectsReducer from './projectsReducer'
import projectMountsReducer from './projectMountsReducer'
import usersReducer from './usersReducer'
import projectRolesReducer from './projectRolesReducer'
import rolesReducer from './rolesReducer'

import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase'

const rootReducer = combineReducers({
  auth: authReducer,
  permissions: permissionsReducer,
  tenants: tenantsReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  changes: changesReducer,
  shares: sharesReducer,
  feedback: feedbackReducer,
  projects: projectsReducer,
  projectMounts: projectMountsReducer,
  projectRoles: projectRolesReducer,
  roles: rolesReducer,
  users: usersReducer,
});

export default rootReducer