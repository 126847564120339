import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './reducers/rootReducer'
import thunk from 'redux-thunk';
import { reduxFirestore, getFirestore } from 'redux-firestore';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import fbConfig from '../config/firebase'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

const middlewares = [
    thunk.withExtraArgument({getFirebase, getFirestore})
];

let reduxPersistKeys = []

if (process.env.NODE_ENV === `development`) {
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({ collapsed: true });

  middlewares.push(logger);

  reduxPersistKeys = ['permissions'] // only these keys from redux will be persisted
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: reduxPersistKeys
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export default (initialState) => {

  let store = createStore(persistedReducer, initialState, compose(
    applyMiddleware(...middlewares),
    reactReduxFirebase(fbConfig, {attachAuthIsReady: true}), // redux binding for firebase
    reduxFirestore(fbConfig) // redux bindings for firestore
  ))
  let persistor = persistStore(store)

  return { store, persistor }
}


/*export default (initialState) => {
  let store = createStore(persistedReducer, initialState, applyMiddleware(...middleware))
  let persistor = persistStore(store)
  return { store, persistor }
}

export const store = createStore(rootReducer,
    compose(
      applyMiddleware(...middlewares),
      reactReduxFirebase(fbConfig, {attachAuthIsReady: true}), // redux binding for firebase
      reduxFirestore(fbConfig) // redux bindings for firestore
    )
);*/

// , {userProfile: 'users', useFirestoreForProfile: true, attachAuthIsReady: true}