import * as actTypes from '../actions/actionTypes'
import { BugCrash } from '../../services/BugCrash'

import Axios from '../../services/Axios'
import {lbl} from '../../lbls'
import { discardChgs } from './changesActions';
import { registerFeedback } from './feedbackActions';

export const loadProjectRoles = (projId) => {

    return async (dispatch, getState) => {

        try{
            dispatch({type: actTypes.LOAD_PRJ_ROLES_STARTED, projId})

            const resp = await Axios.get(`/projects/${projId}/roles`)
            if(resp && (resp.status===200)){
                return dispatch({type: actTypes.LOAD_PRJ_ROLES_READY_OK, projId, data: resp.data})
            }

            throw new Error(lbl.err_load_prj_roles)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.LOAD_PRJ_ROLES_READY_PB, projId, err})
        }
    }
}

export const delProjectUserRoles = (custId, projId, usrId) => {
    return async (dispatch, getState) => {

        try{
            dispatch({type: actTypes.DEL_PRJ_USR_ROLES_STARTED, custId, projId, usrId})

            const resp = await Axios.delete(`/projects/${projId}/roles/${usrId}`)
            if(resp && (resp.status===200)){

                const _state = await getState()
                //console.log(_state)
                const _usrState = _state['users'] && _state['users'][custId] && _state['users'][custId].usrs  && _state['users'][custId].usrs[usrId] && _state['users'][custId].usrs[usrId].data
                //console.log(_usrState)

                let _usrInfo = '-'
                if(_usrState){
                    if(_usrState.name){
                        _usrInfo = _usrState.name
                    }
                    else if(_usrState.email){
                        _usrInfo = _usrState.email
                    }
                }

                dispatch(registerFeedback(lbl.feedback_del_usr_roles_ok.replace('%usr', _usrInfo), false))
                dispatch({type: actTypes.DEL_PRJ_USR_ROLES_READY_OK, custId, projId, usrId})
                dispatch(discardChgs(`prjUsrRoles-${projId}-${usrId}`))
                dispatch(loadProjectRoles(projId))
                return
            }

            throw new Error(lbl.err_del_usr_roles_mount)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.DEL_PRJ_USR_ROLES_READY_PB, custId, projId, usrId, err})
        }
    }
}

export const saveProjectUserRoles = (custId, projId, usrId, changes, afterSaveOk) => {
    return async (dispatch, getState) => {

        try{
            dispatch({type: actTypes.SAVE_PRJ_USR_ROLES_STARTED, custId, projId, usrId})

            const _state = await getState()
            const _rolesState = _state['roles'] && _state['roles'].roles
            const _prjRolesState = _state['projectRoles'] 
            const _usrState = _state['users'] && _state['users'][custId] && _state['users'][custId].usrs  && _state['users'][custId].usrs[usrId] && _state['users'][custId].usrs[usrId].data
            
            let _usrInfo = '-'
            if(_usrState){
                if(_usrState.name){
                    _usrInfo = _usrState.name
                }
                else if(_usrState.email){
                    _usrInfo = _usrState.email
                }
            }

            const _origVersion = _prjRolesState && _prjRolesState[projId] && _prjRolesState[projId].usrRoles 
                                    && _prjRolesState[projId].usrRoles[usrId] && _prjRolesState[projId].usrRoles[usrId].data
                                    && _prjRolesState[projId].usrRoles[usrId].data.roles

            const _checkedRoles4Usr = []

            Object.keys(_rolesState).forEach((roleId) => {
                if(changes && changes[roleId]){ // roleId has changes
                    if(changes[roleId].val){
                        _checkedRoles4Usr.push(roleId)
                    }
                }
                else{ // no changes -> check if it was checked at the begining...
                    if(_origVersion && _origVersion.includes(roleId)){
                        _checkedRoles4Usr.push(roleId)
                    }
                }
            })

            //console.log(_checkedRoles4Usr)

            const resp = (_checkedRoles4Usr.length===0)?(await Axios.delete(`/projects/${projId}/roles/${usrId}`)):(await Axios.post(`/projects/${projId}/roles/${usrId}`, {
                roles: _checkedRoles4Usr
            }))

            if(resp && (resp.status===200)){

                dispatch(registerFeedback(lbl.feedback_save_usr_roles_ok.replace('%usr', _usrInfo), false))
                dispatch(loadProjectRoles(projId))
                dispatch(discardChgs(`prjUsrRoles-${projId}-${usrId}`))
                dispatch({type: actTypes.SAVE_PRJ_USR_ROLES_READY_OK, custId, projId, usrId})

                if(afterSaveOk){
                    window.setTimeout(() => {
                        afterSaveOk()
                    }, 100)
                }

                return 
            }
            else{
                //TODO: Save-User-Project-Roles Fehler Behandlung
            }

            //throw new Error(lbl.err_load_my_cust)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.SAVE_PRJ_USR_ROLES_READY_PB, custId, projId, usrId, err})
        }
    }
}
