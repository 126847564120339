import React from 'react';
import { Route, withRouter } from 'react-router-dom'
import {parse} from 'query-string';

import Dashboard from './Dashboard'
import SignIn from './auth/SignIn'
import AdmTntMain from './admin/tenants/AdmTntMain'
import TestMain from './test/TestMain';
import AdmPrjMain from './admin/projects/AdmPrjMain';

function RouteDispatcher({location, xs}){

    const _qs = parse(location.search) || {}
    console.log(`RouteDispatcher::${xs} %o`, _qs )
  
    return (<React.Fragment>
      <Route path='/tst' component={TestMain} ></Route>
      <Route exact path='/' component={Dashboard} ></Route>
      <Route path='/login' component={SignIn} ></Route>
      <Route path='/admin/tenants' render={(props) => <AdmTntMain key={`AdmTntMain-${_qs['c']}`} xs={xs} qs={_qs} {...props} />} ></Route>
      <Route path='/admin/projects' render={(props) => <AdmPrjMain key={`AdmPrjMain-${_qs['c']}`} xs={xs} qs={_qs} {...props} />} ></Route>
    </React.Fragment>)
}

export default withRouter(RouteDispatcher)