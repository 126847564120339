import * as actTypes from '../actions/actionTypes'
import { BugCrash } from '../../services/BugCrash'

import Axios from '../../services/Axios'
import {lbl} from '../../lbls'

export const loadCustUsers = (custId) => {
    return async (dispatch, getState) => {

        try{
            dispatch({type: actTypes.LOAD_CUST_USERS_STARTED, custId})

            const resp = await Axios.get(`/customers/${custId}/users`)
            if(resp && (resp.status===200)){
                return dispatch({type: actTypes.LOAD_CUST_USERS_READY_OK, custId, data: resp.data})
            }

            throw new Error(lbl.err_load_cust_users)
        }
        catch(err){
            BugCrash.notify(err)
            dispatch({type: actTypes.LOAD_CUST_USERS_READY_PB, custId, err})
        }
    }
}
