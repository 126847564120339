import React from 'react'
import { lbl } from '../../../lbls';
import HeadCont from '../../ui/HeadCont';

export default function PrjDashboard() {
    return (
        <React.Fragment>
            <HeadCont title={lbl.dash} hideBack={true} />

            <div className="NRBody p-3">
                {lbl.bitte_prj}
            </div>
            <footer className="NRFoot d-flex justify-content-around"></footer>
        </React.Fragment>
    )
}