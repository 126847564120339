import React, {useState} from 'react'
import PropTypes from 'prop-types';
import PlsWait from '../../ui/PlsWait';
import { lbl } from '../../../lbls';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { pageOptions, selectRow } from '../../ui/TblUtils';

import { dbg } from '../../../utils/logging';
import Button from 'react-bootstrap/Button';
import calcPath from '../../../services/CalcPath';
import { IoIosRemoveCircleOutline } from 'react-icons/io';
import ConfDel from '../../ui/ConfDel';
import ContextInfo from '../../ui/ContextInfo';

function isDeleting(actions) {

    if(!actions){
        return false
    }

    return Object.values(actions).reduce((prvVal, curObj) => {
        return prvVal || curObj.del
    }, false)
}

function PrjMountsLst({loading, prj, mounts, custTnts, onSelMnt, onCreateMnt, actions, onDelMnt}) {
    const [del, setDel] = useState('');

    const _doCloseDelDialog = () => {setDel('');}
    const _isDeleting = isDeleting(actions)

    dbg(`PrjMountsLst RENDER: ProjektID=${prj.id}`)

    const _ownCustTnts = ((!loading && custTnts && custTnts.tnts && Object.values(custTnts.tnts)) || []).map(t => {
        return {
            id: t.data && t.data.id,
            name: t.data && t.data.name,
            parent: t.data && t.data.parent,
        }
    })
    const _prepTnts = calcPath(_ownCustTnts).reduce((prvVal, curObj)=>{
        prvVal[curObj.id]=curObj
        return prvVal
    }, {})
    //console.log(_prepTnts)

    //console.log(mounts)
    const _mountsPrep = ((loading || !mounts)?[]:Object.values(mounts.mounts)).map(m => {
        return {
            id: m.data && m.data.id,
            name: m.data && m.data.name,
            path: m.data && m.data.id && _prepTnts && _prepTnts[m.data.id] && _prepTnts[m.data.id]['path'],
        }
    })
    //console.log(_mountsPrep)

    const _selectRow = {
        ...selectRow,
        selected: [],
    };
    const _defaultSorted = [{
        dataField: 'name',
        order: 'asc'
    }];

    const { SearchBar } = Search;

    return <React.Fragment>

            <ContextInfo infos={[
                {left: lbl.prj, right: prj && prj.name},
            ]} />


            { loading && !_isDeleting && <PlsWait msg={lbl.load_pls_wait} /> }
            { _isDeleting && <PlsWait msg={lbl.del_pls_wait} /> }

            <ConfDel title={lbl.del_prj_mnt} question={lbl.del_conf} doClose={_doCloseDelDialog} id={del} doDel={(id) => {
                onDelMnt(id)
            }} />

            <div className="pl-3 pr-3">
                <ToolkitProvider
                    keyField="id"
                    data={_mountsPrep}
                    columns={[
                        {
                            dataField: 'name',
                            isDummyField: true,
                            sort: true,
                            text: 'Name',
                            formatter: (cellContent, row) => {
                                return (<React.Fragment>
                                    <p className='lstItmTxt'>{row['name']}</p>
                                    <p className='lstItmDesc'>{row['path']}</p>
                                </React.Fragment>)
                            },
                            events: {
                                onClick: (e, column, columnIndex, row, rowIndex) => {
                                    console.log(row)
                                    //onChgPrj(row)
                                }
                            }
                        },
                        {
                            dataField: 'path',
                            searchable: true,
                            hidden: true,
                        },
                        {
                            dataField: 'id',
                            searchable: false,
                            isDummyField: true,
                            text: '+',
                            style: {width: 50},
                            formatter: (cellContent, row) => {
                                return <div className="d-flex justify-content-center">

                                    <Button variant="link" className="btn txt-act-color" onClick={
                                        (evnt) => {
                                            setDel(row['id'])
                                        }
                                    }><IoIosRemoveCircleOutline size={30} /></Button>
                                </div>
                            }
                        }
                    ]}
                    search
                >
                    {
                        props => (
                            <React.Fragment>
                                <SearchBar 
                                    className='searchBox'
                                    delay={700}
                                    placeholder={lbl.search_tnt}
                                    { ...props.searchProps } 
                                />

                                <BootstrapTable
                                    bootstrap4
                                    striped
                                    hover
                                    headerClasses="d-none"
                                    defaultSorted={_defaultSorted}
                                    noDataIndication={(loading)?lbl.load_pls_wait:lbl.no_data}
                                    pagination={ paginationFactory(pageOptions) }
                                    selectRow={_selectRow}
                                    { ...props.baseProps }
                                />
                            </React.Fragment>
                        )
                    }
                </ToolkitProvider>

                <div className="d-flex justify-content-between dividerTop mt-1 pt-2">
                    <Button onClick={(evnt) => {onCreateMnt();}} variant="outline-primary" size="sm" type="submit">{lbl.mnt_tnt}</Button>
                </div>
            </div>

    </React.Fragment>
}

PrjMountsLst.propTypes = {
    loading: PropTypes.bool.isRequired,
    prj: PropTypes.object.isRequired,
    mounts: PropTypes.object.isRequired,
    onSelMnt: PropTypes.func.isRequired,
    onCreateMnt: PropTypes.func.isRequired,
    onDelMnt: PropTypes.func.isRequired,
}

export default PrjMountsLst