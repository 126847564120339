import * as actTypes from '../actions/actionTypes'

const initState = {
}

const projectsReducer = (state = initState, action) => {

  switch(action.type){

    case actTypes.LOAD_CUST_PRJS_STARTED:

        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                loading: true,
                err: null,
                prjs: {},
            }
        }

    case actTypes.LOAD_CUST_PRJS_READY_PB:

        console.log(`TODO: LOAD_CUST_PRJS_READY_PB - Not implemented yet!! `)
        return state

    case actTypes.LOAD_CUST_PRJS_READY_OK:

        const prjs={}
        action.data.list.forEach(prj => {
            prjs[prj.id] = Object.assign({}, state[action.custId][prj.id], {data: prj})
        })

        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                loading: false,
                err: null,
                prjs,
            }
        }

    case actTypes.LOAD_PRJ_STARTED:
        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                prjs: {
                    ...(state && state[action.custId] && state[action.custId].prjs),
                    [action.prjId]: {
                        ...(state && state[action.custId] && state[action.custId].prjs && state[action.custId].prjs[action.prjId]),
                        loading: true,
                        err: null,    
                    }
                }
            }
        }

    case actTypes.LOAD_PRJ_READY_PB:
        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                prjs: {
                    ...(state && state[action.custId] && state[action.custId].prjs),
                    [action.prjId]: {
                        ...(state && state[action.custId] && state[action.custId].prjs && state[action.custId].prjs[action.prjId]),
                        loading: false,
                        err: action.err,
                    }
                }
            }
        }

        

    case actTypes.LOAD_PRJ_READY_OK:
        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                prjs: {
                    ...(state && state[action.custId] && state[action.custId].prjs),
                    [action.prjId]: {
                        ...(state && state[action.custId] && state[action.custId].prjs && state[action.custId].prjs[action.prjId]),
                        loading: false,
                        err: null,
                        new: false,
                        data: {
                            ...(state && state[action.custId] && state[action.custId].prjs && state[action.custId].prjs[action.prjId] && state[action.custId].prjs[action.prjId].data),
                            ...action.data,
                        },
                    }
                }
            }
        }

    case actTypes.ADD_PRJ:
        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                prjs: {
                    ...(state && state[action.custId] && state[action.custId].prjs),
                    [action.prjId]: {
                        ...(state && state[action.custId] && state[action.custId].prjs && state[action.custId].prjs[action.prjId]),
                        loading: false,
                        err: null,
                        new: true,
                        data: {
                            ...(state && state[action.custId] && state[action.custId].prjs && state[action.custId].prjs[action.prjId] && state[action.custId].prjs[action.prjId].data),
                            name: action.name,
                            id: action.prjId,
                        },
                    }
                }
            }
        }
        
    case actTypes.SAVE_PRJ_STARTED:
        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                prjs: {
                    ...(state && state[action.custId] && state[action.custId].prjs),
                    [action.prjId]: {
                        ...(state && state[action.custId] && state[action.custId].prjs && state[action.custId].prjs[action.prjId]),
                        saving: true,
                        errSave: null,    
                    }
                }
            }
        }

    case actTypes.SAVE_PRJ_READY_PB:
        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                prjs: {
                    ...(state && state[action.custId] && state[action.custId].prjs),
                    [action.prjId]: {
                        ...(state && state[action.custId] && state[action.custId].prjs && state[action.custId].prjs[action.prjId]),
                        saving: false,
                        errSave: action.err,    
                    }
                }
            }
        }

    case actTypes.SAVE_PRJ_READY_OK:
    
        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                prjs: {
                    ...(state && state[action.custId] && state[action.custId].prjs),
                    [action.prjId]: {
                        ...(state && state[action.custId] && state[action.custId].prjs && state[action.custId].prjs[action.prjId]),
                        saving: false,
                        errSave: null,
                        new: false,
                    }
                }
            }
        }

    case actTypes.DEL_PRJ_STARTED:
        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                prjs: {
                    ...(state && state[action.custId] && state[action.custId].prjs),
                    [action.prjId]: {
                        ...(state && state[action.custId] && state[action.custId].prjs && state[action.custId].prjs[action.prjId]),
                        deleting: true,
                        errDel: null,    
                    }
                }
            }
        }
        
    case actTypes.DEL_PRJ_READY_PB:

        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                prjs: {
                    ...(state && state[action.custId] && state[action.custId].prjs),
                    [action.prjId]: {
                        ...(state && state[action.custId] && state[action.custId].prjs && state[action.custId].prjs[action.prjId]),
                        deleting: false,
                        errDel: action.err,    
                    }
                }
            }
        }

    case actTypes.DEL_PRJ_READY_OK:

        return {
            ...state,
            [action.custId]: {
                ...(state && state[action.custId]),
                prjs: Object.keys(state[action.custId].prjs).reduce(
                    (obj, key) => {
                        if (key !== action.prjId) {
                            obj[key] = state[action.custId].prjs[key]
                        }
                        return obj
                    },
                    {}
                )
            }
        }
        
    default:
        return state
  }
}
        
export default projectsReducer;