import React, {useState} from 'react'
import PropTypes from 'prop-types';
import PlsWait from '../../ui/PlsWait';
import { lbl } from '../../../lbls';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { pageOptions, selectRow } from '../../ui/TblUtils';

import { dbg } from '../../../utils/logging';
import Button from 'react-bootstrap/Button';

import { IoIosRemoveCircleOutline } from 'react-icons/io';
import ConfDel from '../../ui/ConfDel';
import ContextInfo from '../../ui/ContextInfo';

function isDeleting(actions) {

    if(!actions){
        return false
    }

    return Object.values(actions).reduce((prvVal, curObj) => {
        return prvVal || curObj.deleting
    }, false)
}

function userRoleName(ur){

    if(!ur || !ur.data){
        return '-'
    }

    const _data = ur.data
    if(_data['name'] && _data['email']){
        return `${_data['name']} (${_data['email']})`
    }

    if(_data['name']){
        return _data['name']
    }

    return _data['email']

}

function prepUsrRoles(ur, roles){

    if(!ur || !ur.data || !ur.data.roles){
        return '-'
    }

    const _getRoleName = (roleId) => {
        if(roles && roles[roleId]){
            return roles[roleId].name
        }

        return '-'
    }

    const _usrRoles = ur.data.roles.reduce((prv, cur) => {
        return [
            ...prv,
            _getRoleName(cur)
        ]
    }, [])

    return _usrRoles.sort((r1, r2) => r1>=r2).reduce((prv, cur) => {
        const sep = (prv && cur)?', ':''
        return `${prv}${sep}${cur}`
    }, "")
}

function PrjRolesLst({loading, prj, prjRoles, roles, onSelUser, onNewUsrRollen, actions, onDelUser}) {
    const [del, setDel] = useState('');

    const _doCloseDelDialog = () => {setDel('');}
    const _isDeleting = isDeleting(actions)

    console.log(actions)
    console.log(`Deleting: ${_isDeleting}`)

    dbg(`PrjRolesLst RENDER: ProjektID=${prj.id}`)

    const _roles = (roles && roles.roles) || {}

    //console.log(prjRoles)
    const _mountsPrep = ((loading || !prjRoles)?[]:Object.values(prjRoles.usrRoles)).map(ur => {
        return {
            id: ur.data && ur.data.id,
            name: userRoleName(ur),
            roles: prepUsrRoles(ur, _roles),
        }
    })
    //console.log(_mountsPrep)

    const _selectRow = {
        ...selectRow,
        selected: [],
    };
    const _defaultSorted = [{
        dataField: 'name',
        order: 'asc'
    }];

    const { SearchBar } = Search;

    return <React.Fragment>

            <ContextInfo infos={[
                {left: lbl.prj, right: prj && prj.name},
            ]} />

            { loading && !_isDeleting && <PlsWait msg={lbl.load_pls_wait} /> }
            { _isDeleting && <PlsWait msg={lbl.del_pls_wait} /> }

            <ConfDel title={lbl.del_prj_usr_roles} question={lbl.del_conf} doClose={_doCloseDelDialog} id={del} doDel={(id) => {
                onDelUser(id)
            }} />

            <div className="pl-3 pr-3">            
                <ToolkitProvider
                    keyField="id"
                    data={_mountsPrep}
                    columns={[
                        {
                            dataField: 'name',
                            isDummyField: true,
                            sort: true,
                            text: 'Name',
                            formatter: (cellContent, row) => {
                                return (<React.Fragment>
                                    <p className='lstItmTxt'>{row['name']}</p>
                                    <p className='lstItmDesc'>{row['roles']}</p>
                                </React.Fragment>)
                            },
                            events: {
                                onClick: (e, column, columnIndex, row, rowIndex) => {
                                    console.log(row)
                                    onSelUser(row['id'])
                                }
                            }
                        },
                        {
                            dataField: 'roles',
                            searchable: true,
                            hidden: true,
                        },
                        {
                            dataField: 'id',
                            searchable: false,
                            isDummyField: true,
                            text: '+',
                            style: {width: 50},
                            formatter: (cellContent, row) => {
                                return <div className="d-flex justify-content-center">

                                    <Button variant="link" className="btn txt-act-color" onClick={
                                        (evnt) => {
                                            setDel(row['id'])
                                        }
                                    }><IoIosRemoveCircleOutline size={30} /></Button>
                                </div>
                            }
                        }
                    ]}
                    search
                >
                    {
                        props => (
                            <React.Fragment>
                                <SearchBar 
                                    className='searchBox'
                                    delay={700}
                                    placeholder={lbl.search_usr_role}
                                    { ...props.searchProps } 
                                />

                                <BootstrapTable
                                    bootstrap4
                                    striped
                                    hover
                                    headerClasses="d-none"
                                    defaultSorted={_defaultSorted}
                                    noDataIndication={(loading)?lbl.load_pls_wait:lbl.no_data}
                                    pagination={ paginationFactory(pageOptions) }
                                    selectRow={_selectRow}
                                    { ...props.baseProps }
                                />
                            </React.Fragment>
                        )
                    }
                </ToolkitProvider>

                <div className="d-flex justify-content-between dividerTop mt-3 pt-2">
                    <Button onClick={(evnt) => {onNewUsrRollen();}} variant="outline-primary" size="sm" type="submit">{lbl.new_usr_role}</Button>
                </div>
            </div>

    </React.Fragment>
}

PrjRolesLst.propTypes = {
    loading: PropTypes.bool.isRequired,
    prj: PropTypes.object.isRequired,
    prjRoles: PropTypes.object.isRequired,
    roles: PropTypes.object.isRequired,
    onSelUser: PropTypes.func.isRequired,
    onNewUsrRollen: PropTypes.func.isRequired,
    onDelUser: PropTypes.func.isRequired,
}

export default PrjRolesLst