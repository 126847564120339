import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';

import { connect } from 'react-redux'
import PrjDetails from './PrjDetails';

import {debug} from '../../../utils/logging'
import genPath from '../../../routing/genPath';

import { loadProject, saveProject, delProject} from '../../../store/actions/projectsActions';
import { registerChg } from '../../../store/actions/changesActions';
import { loadRoles } from '../../../store/actions/rolesActions';

class PrjDetailsCont extends PureComponent {

    static propTypes = {
        history: PropTypes.object,
        custId: PropTypes.string,
        tntId: PropTypes.string,
        tab: PropTypes.string,
        qs: PropTypes.object,
    }

    _onChgTab = (selTab) => {
        const {history, custId, prjId} = this.props
        history.push(genPath('/admin/projects', null, {c: custId, p: prjId, act: 'det', tab: selTab}))
    }

    _onChgPrj = (prj) => {
        const {history, custId} = this.props
        const selTab = 'data'

        history.push(genPath('/admin/projects', null, {c: custId, p: prj.data.id, act: 'det', tab: selTab}))
    }

    _delPrj = (prjId, prjName) => {
        const {history, custId, delProject} = this.props
        delProject(custId, prjId, prjName, () => {
            history.push(genPath('/admin/projects', null, {c: custId}))
        })
    }

    _onSaveData = (changes) => {
        const {custId, prjId} = this.props
        this.props.saveProject(custId, prjId, changes)
    }

    _registerChg = (fieldName, value) => {
        const {prjId} = this.props
        this.props.registerChg(`prj-${prjId}`, fieldName, value)
    }

    componentDidMount = () => {

        const {custId, prjId} = this.props

        debug(`PrjDetailsCont CDM: ProjektID=${prjId}`)

        this.props.loadProject(custId, prjId)
        this.props.loadRoles()
    }

    render = () => {

        const {history, custId, prjId, tab, prjDet, custDet, projects, qs} = this.props

        debug(`PrjDetailsCont RENDER: ProjektID=${prjId}`)

        return (<React.Fragment>
            <PrjDetails 
                history={history}
                custId={custId} 
                prjId={prjId} 
                custDet={custDet}
                prjDet={prjDet}
                onChgTab={this._onChgTab} 
                tab={tab} 
                onSaveData={this._onSaveData} 
                changes={this.props.changes || {}}
                registerChg={this._registerChg}
                projects={projects || {}}
                onChgPrj={this._onChgPrj}
                delPrj={this._delPrj}
                qs={qs}
            />
        </React.Fragment>)
    }
}

const mapStateToProps = (state, ownProps) => {

    const {custId, prjId} = ownProps

    return{
        custDet: state.permissions && state.permissions.myCust 
                    && !state.permissions.myCust.loading 
                    && (state.permissions.myCust.lastLoad > 0)
                    && state.permissions.myCust.list 
                    && state.permissions.myCust.list.find(c => c.id === custId), 

        prjDet: state.projects && state.projects[custId] && state.projects[custId].prjs && state.projects[custId].prjs[prjId],
        changes: prjId && state.changes[`prj-${prjId}`],
        projects: state.projects && state.projects[custId]
    }
}

const mapDispatchToProps = (dispatch) => {

    return {
        loadProject: (custId, prjId) => dispatch(loadProject(custId, prjId)),
        loadRoles: () => dispatch(loadRoles()),
        registerChg: (objKennung, fieldName, value) => dispatch(registerChg(objKennung, fieldName, value)),
        saveProject: (custId, prjId, changes) => dispatch(saveProject(custId, prjId, changes)),
        delProject: (custId, prjId, prjName, afterDelOk) => dispatch(delProject(custId, prjId, prjName, afterDelOk)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrjDetailsCont)
