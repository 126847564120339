import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import GrpHead from '../../ui/GrpHead';
import PlsWait from '../../ui/PlsWait';
import { lbl } from '../../../lbls';

// TODO: loadPb, savePb
// TODO: tnt @ createNew
// TODO: Validierung
// TODO: xs -> sm Design
// TODO: Delete action
// TODO: Save action

function TntDetailsData({tnt, loading, saving, onSave, registerChg, changes}) {
    const [validated, setValidated] = useState(false);

    const handleSubmit = event => {

      const form = event.currentTarget;

      event.preventDefault();
      event.stopPropagation();

      setValidated(true);

      if (form.checkValidity() === false) {
        return false
      }

      onSave(changes)
    };

    const _handleChange = (evnt) => {
        const {name, value} = evnt.target
        registerChg(name, value)
    }

    return (
        <React.Fragment>
            { saving && <PlsWait msg={lbl.save_pls_wait} /> }
            { loading && !saving && <PlsWait msg={lbl.load_pls_wait} /> }

            <Form className="p-3" noValidate validated={validated} onSubmit={handleSubmit}>

                <GrpHead title={lbl.info} />

                <Form.Group as={Row} controlId="inpName">
                    <Form.Label column xs="12" sm="3">{lbl.name}:</Form.Label>
                    <Col xs="12" sm="9">
                        <Form.Control name="name" type="text" placeholder={lbl.name} 
                            value={changes.name?changes.name.val:(tnt.name || '')} 
                            onChange={_handleChange} required />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="selContainer">
                    <Form.Label column xs="12" sm="3">{lbl.tnt_container}:</Form.Label>
                    <Col xs="12" sm="9">
                        <Form.Control name="container" as="select" placeholder={lbl.tnt_container} 
                            value={changes.container?changes.container.val:((tnt.container)?"TRUE":"FALSE")}
                            onChange={_handleChange} required>
                                <option value="TRUE">{lbl.tnt_container_subtnt}</option>
                                <option value="FALSE">{lbl.tnt_container_daten}</option>
                        </Form.Control>
                    </Col>
                </Form.Group>

                <GrpHead title={lbl.details} />
                <Form.Group as={Row} controlId="inpFirma">
                    <Form.Label column xs="12" sm="3">{lbl.company}:</Form.Label>
                    <Col xs="12" sm="9">
                        <Form.Control name="firma" type="text" placeholder={lbl.company} 
                            value={changes.firma?changes.firma.val:((tnt.compact && tnt.compact.firma) || '')} 
                            onChange={_handleChange} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="inpStreet">
                    <Form.Label column xs="12" sm="3">{lbl.street}:</Form.Label>
                    <Col xs="12" sm="9">
                        <Form.Control name="street" type="text" placeholder={lbl.street} 
                            value={changes.street?changes.street.val:((tnt.compact && tnt.compact.street) || '')} 
                            onChange={_handleChange} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="inpZip">
                    <Form.Label column xs="12" sm="3">{lbl.zip}:</Form.Label>
                    <Col xs="12" sm="9">
                        <Form.Control name="zip" type="text" placeholder={lbl.zip} 
                            value={changes.zip?changes.zip.val:((tnt.compact && tnt.compact.zip) || '')} 
                            onChange={_handleChange} />                        
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="inpCity">
                    <Form.Label column xs="12" sm="3">{lbl.city}:</Form.Label>
                    <Col xs="12" sm="9">
                        <Form.Control name="city" type="text" placeholder={lbl.city} 
                            value={changes.city?changes.city.val:((tnt.compact && tnt.compact.city) || '')} 
                            onChange={_handleChange} />
                    </Col>
                </Form.Group>

                <div className="d-flex justify-content-between dividerTop mt-3 pt-2">
                    <Button variant="outline-primary" size="sm" type="submit">{lbl.save}</Button>
                    <Button variant="outline-danger" disabled size="sm" type="button">{lbl.del}</Button>
                </div>
            </Form>
        </React.Fragment>
  )
}

TntDetailsData.propTypes = {
    loading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    tnt: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    registerChg: PropTypes.func.isRequired,
    changes: PropTypes.object.isRequired, 
}

export default TntDetailsData