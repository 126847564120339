import React from 'react'
import PropTypes from 'prop-types';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { pageOptions, selectRow } from '../../ui/TblUtils';

import PlsWait from '../../ui/PlsWait';
import { lbl } from '../../../lbls';
import { IoIosAddCircleOutline } from 'react-icons/io';
import Button from 'react-bootstrap/Button';
import calcPath from '../../../services/CalcPath';
import ContextInfo from '../../ui/ContextInfo';

function isSaving(actions) {

    if(!actions){
        return false
    }

    return Object.values(actions).reduce((prvVal, curObj) => {
        return prvVal || curObj.saving
    }, false)
}

function PrjMountCreate({loading, prj, custTnts, addCustTnt, actions}) {

    //console.log(custTnts)
    //console.log(actions)

    const _isSaving = isSaving(actions)

    const _ownCustTnts = ((!loading && custTnts && custTnts.tnts && Object.values(custTnts.tnts)) || []).map(t => {
        return {
            id: t.data && t.data.id,
            name: t.data && t.data.name,
            parent: t.data && t.data.parent,
        }
    })
    const _prepTnts = [...calcPath(_ownCustTnts)]

    //console.log(_prepTnts)

    const _selectRow = {
        ...selectRow,
        selected: [],
    };
    const _defaultSorted = [{
        dataField: 'name',
        order: 'asc'
    }];

    const { SearchBar } = Search;

    return <React.Fragment>
            <ContextInfo infos={[
                {left: lbl.prj, right: prj && prj.name},
            ]} />

            { loading && <PlsWait msg={lbl.load_pls_wait} /> }
            { _isSaving && <PlsWait msg={lbl.save_pls_wait} /> }

            <div className="pl-3 pr-3">
                <ToolkitProvider
                    keyField="id"
                    data={_prepTnts}
                    columns={[
                        {
                            dataField: 'name',
                            isDummyField: true,
                            sort: true,
                            text: 'Name',
                            formatter: (cellContent, row) => {
                                return (<React.Fragment>
                                    <p className='lstItmTxt'>{row['name']}</p>
                                    <p className='lstItmDesc'>{row['path']}</p>
                                </React.Fragment>)
                            },
                            events: {
                                onClick: (e, column, columnIndex, row, rowIndex) => {
                                    console.log(row)
                                    //onChgPrj(row)
                                }
                            }
                        },
                        {
                            dataField: 'path',
                            searchable: true,
                            hidden: true,
                        },
                        {
                            dataField: 'id',
                            searchable: false,
                            isDummyField: true,
                            text: '+',
                            style: {width: 50},
                            formatter: (cellContent, row) => {
                                return <div className="d-flex justify-content-center">
                                    <Button variant="link" className="btn txt-act-color" onClick={
                                        (evnt) => {
                                            addCustTnt(row['id'])
                                        }
                                    }><IoIosAddCircleOutline size={30} /></Button>
                                </div>
                            }
                        }
                    ]}
                    search
                >
                    {
                        props => (
                            <React.Fragment>
                                <SearchBar 
                                    className='searchBox'
                                    delay={700}
                                    placeholder={lbl.search_tnt}
                                    { ...props.searchProps } 
                                />

                                <BootstrapTable
                                    bootstrap4
                                    striped
                                    hover
                                    headerClasses="d-none"
                                    defaultSorted={_defaultSorted}
                                    noDataIndication={(loading)?lbl.load_pls_wait:lbl.no_data}
                                    pagination={ paginationFactory(pageOptions) }
                                    selectRow={_selectRow}
                                    { ...props.baseProps }
                                />
                            </React.Fragment>
                        )
                    }
                </ToolkitProvider>
            </div>
        </React.Fragment>
}

PrjMountCreate.propTypes = {
    loading: PropTypes.bool.isRequired,
    prj: PropTypes.object.isRequired,
    custTnts: PropTypes.object,
    addCustTnt: PropTypes.func.isRequired,
    actions: PropTypes.object,
}

export default PrjMountCreate