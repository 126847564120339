import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';

import { connect } from 'react-redux'

import {debug} from '../../../utils/logging'
import genPath from '../../../routing/genPath';
import PrjLst from './PrjLst';
import { getUuid } from '../../../services/IdGenerator';
import { addProject } from '../../../store/actions/projectsActions';
import { lbl } from '../../../lbls';

class PrjLstCont extends PureComponent {

    static propTypes = {
        history: PropTypes.object,
        custId: PropTypes.string,
        prjId: PropTypes.string,
    }

    _onChgPrj = (prj) => {
        const {history, custId} = this.props
        const selTab = 'data'

        history.push(genPath('/admin/projects', null, {c: custId, p: prj.data.id, act: 'det', tab: selTab}))
    }

    _goBack = () => {
        const {history} = this.props
        //history.goBack()
        history.push('/')
    }

    _addPrj = () => {

        const {history, custId, addProject} = this.props
        const selTab = 'data'
        const _newPrjId = getUuid()

        addProject(custId, _newPrjId, lbl.new_prj, () => {
            history.push(genPath('/admin/projects', null, {c: custId, p: _newPrjId, act: 'det', tab: selTab}))
        })
    }

    componentDidMount = () => {

        const {custId, prjId} = this.props

        debug(`PrjLstCont CDM: CustID=${custId}, ProjektID=${prjId}`)
    }

    render = () => {

        const {custId, prjId, projects} = this.props
        debug(`PrjLstCont RENDER: CustID=${custId}, ProjektID=${prjId}`)

        //console.log(projects)

        return (<React.Fragment>
            <PrjLst 
                prjId={prjId} 
                projects={projects || {}}
                onChgPrj={this._onChgPrj}
                goBack={this._goBack}
                addPrj={this._addPrj}
                delPrj={this._delPrj}
            />
        </React.Fragment>)
    }
}

const mapStateToProps = (state, ownProps) => {

    const {custId} = ownProps

    return{
        projects: state.projects && state.projects[custId]
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addProject: (custId, prjId, name, afterCreate) => dispatch(addProject(custId, prjId, name, afterCreate)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrjLstCont)